import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import TextInput from "../../form/TextInput";
import RoleInput from "../../form/RoleInput";
import {RoleResponse} from "../../../generated/models/RoleResponse";

export interface Props {
    id: string
    roles: RoleResponse[]

    descriptionError: string | null
    rolesError: string | null
}

export default function (props: Props) {
    const [descriptionError, setDescriptionError] = useState<string | null>(props.descriptionError)
    const [rolesError, setRolesError] = useState<string | null>(props.rolesError)

    useEffect(() => setDescriptionError(props.descriptionError), [props.descriptionError])
    useEffect(() => setRolesError(props.rolesError), [props.rolesError])

    return (<>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-description`} label="Description" name="description" error={descriptionError}
                       required={false} helpMessage="The description of the API key." multiline={true}/>
        </Grid>
        <Grid item xs={12}>
            <RoleInput id={`${props.id}-roles`} name="roles" options={props.roles} error={props.rolesError}/>
        </Grid>
    </>)
}
