import {Autocomplete, Checkbox, TextField} from "@mui/material";
import {CheckBoxOutlineBlankSharp, CheckBoxSharp} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {RoleResponse} from "../../generated/models/RoleResponse";

export interface Props {
    id: string
    label?: string
    name?: string
    options: RoleResponse[]
    value?: RoleResponse[]
    onChange?: (roles: RoleResponse[]) => void
    error?: string | null
    helpMessage?: string
    invalidMessage?: string
    multiple?: boolean
    readonly?: boolean
    disabled?: boolean
}

export default function (props: Props) {
    const DEFAULT_LABEL: string = "Roles"
    const DEFAULT_NAME: string = "roles"
    const DEFAULT_HELP_MESSAGE: string = "Select roles."

    const name: string = props.name || DEFAULT_NAME
    const multiple: boolean = props.multiple === undefined ? true : props.multiple

    const [value, setValue] = useState<RoleResponse[]>(props.value === undefined || props.value === null ? [] : props.value)
    const [error, setError] = useState<string | null>(props.error === undefined || props.error === null ? null : props.error)

    useEffect(() => setValue(props.value === undefined || props.value === null ? [] : props.value), [props.value])
    useEffect(() => setError(props.error === undefined || props.error === null ? null : props.error), [props.error])

    return (<>
        <input id={`${props.id}-form-${name}`} type="hidden" name={name} defaultValue={value.map(e => e?.id).join()}/>
        <Autocomplete id={`${props.id}-form-${name}-input`} multiple={multiple} fullWidth disableCloseOnSelect
                      options={props.options || []} size="small"
                      getOptionLabel={(option: RoleResponse | undefined) => option?.displayName || "?"}
                      isOptionEqualToValue={(option, value) => option.id === value?.id}
                      onChange={(event, values) => {
                          let roles: RoleResponse[] = []
                          if (Array.isArray(values)) {
                              roles = values
                          } else if (values) {
                              roles = [values]
                          }
                          setValue(roles)
                          console.debug(`Set value of roles to`, value)
                          if (props.onChange) props.onChange(roles)
                      }}
                      value={value}
                      renderOption={(renderProps, option, {selected}) => (
                              <li {...renderProps}>
                                  <Checkbox id={`${props.id}-option-${option.id}`}
                                            name={`${name}-${option.id}`} checked={selected}
                                            data-role-name={`${props.id}-${option.name}`}
                                            icon={<CheckBoxOutlineBlankSharp fontSize="small"/>}
                                            checkedIcon={<CheckBoxSharp fontSize="small"/>}
                                            style={{marginRight: 8}}/>
                                  {option.displayName}
                              </li>
                      )}
                      renderInput={(params) => (
                              <TextField {...params}
                                         variant="outlined"
                                         error={props.error != null}
                                         label={props.label || DEFAULT_LABEL}
                                         helperText={error || props.helpMessage || DEFAULT_HELP_MESSAGE}/>
                      )}/>
    </>)
}
