import React, {SyntheticEvent, useState} from "react";
import FormDialog from "../../dialog/FormDialog";
import {TenantResponse} from "../../../generated/models/TenantResponse";
import {LoginHolder} from "../../provider/LoginProvider";
import {TenantAdapter} from "../../../adapters/TenantAdapter";
import TenantCreateForm from "./TenantCreateForm";

export interface Props {
    id: string
    title: string
    open: boolean
    onClose?: () => void
    onSubmitted?: (response: TenantResponse) => void
    adapter: TenantAdapter
    login: LoginHolder | null
}

export default function (props: Props) {

    const onClose: () => void = () => {
        setLoading(false)
        setError(null)
        setNameError(null)
        setAliasError(null)
        if (props.onClose) props.onClose()
    }

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [nameError, setNameError] = useState<string | null>(null)
    const [aliasError, setAliasError] = useState<string | null>(null)

    const onSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setError(null)
        setNameError(null)
        setAliasError(null)

        const data = new FormData(event.currentTarget as HTMLFormElement)
        const name = (data.get("name") as string).trim()
        const alias = data.get("alias") as string
        props.adapter.createOne(props.login, {name, alias})
                .then((response: TenantResponse) => {
                    if (props.onSubmitted) props.onSubmitted(response)
                })
                .catch(error => setError(error.message))
                .finally(() => setLoading(false))
    }

    return (<FormDialog id={props.id} title={props.title} submitButton="Create"
                        errorTitle="Failed to create element." error={error} open={props.open}
                        isLoading={isLoading}
                        onClose={onClose} onSubmit={onSubmit}>
        <TenantCreateForm id={`${props.id}-form`}
                          nameError={nameError}
                          aliasError={aliasError}
        />
    </FormDialog>)
}
