import React, {useContext, useEffect, useState} from "react";
import {Alert, AlertTitle, IconButton, Tooltip} from "@mui/material";
import AuthenticatedLayout from "../../../components/layout/AuthenticatedLayout";
import {DataGrid, GridRowId, GridSortModel} from "@mui/x-data-grid";
import {BooleanParam, NumberParam, StringParam, useQueryParam} from "use-query-params";
import {useHotkeys} from "react-hotkeys-hook";
import {AddSharp, DeleteSharp, EditSharp} from "@mui/icons-material";
import {assembleSort} from "../../../misc/misc";
import {LoginContext} from "../../provider/LoginProvider";
import {CsvArrayParam} from "../../../misc/CsvArrayParam";
import {FlatPaper} from "../../Misc";
import {Page, pageable, tenantAdapter} from "../../../adapters/interfaces";
import {TenantResponse} from "../../../generated/models/TenantResponse";
import {columns, emptyTenant} from "./TenantModel";
import TenantDeleteDialog from "./TenantDeleteDialog";
import TenantCreateDialog from "./TenantCreateDialog";
import TenantEditDialog from "./TenantEditDialog";
import {RouteComponentProps} from "@reach/router";

export interface Props extends RouteComponentProps {
    title: string
    topic: string
    sort?: string
}

export default function (props: Props) {
    const SORT_DEFAULT: string = props.sort ? encodeURIComponent(props.sort) : encodeURIComponent("+alias")

    const {login} = useContext(LoginContext)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [pageNumber = 0, setPageNumber] = useQueryParam("page", NumberParam)
    const [pageSize = 10, setPageSize] = useQueryParam("size", NumberParam)
    const [sort = SORT_DEFAULT, setSort] = useQueryParam("sort", new CsvArrayParam())
    const [id, setId] = useQueryParam("id", StringParam)
    const [initialSelectionSetFromId, setInitialSelectionSetFromId] = useState<boolean>(false)
    const [createDialogOpen, setCreateDialogOpen] = useQueryParam("create", BooleanParam)
    const [editDialogOpen, setEditDialogOpen] = useQueryParam("edit", BooleanParam)
    const [deleteDialogOpen, setDeleteDialogOpen] = useQueryParam("delete", BooleanParam)
    const [elementsPage, setElementsPage] = useState<Page<TenantResponse> | null>(null)
    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const [selectionModel, setSelectionModel] = useState<GridRowId[]>([])
    const [selectedElement, setSelectedElement] = useState<TenantResponse | null>(null)
    const [selectedElements, setSelectedElements] = useState<TenantResponse[]>([])

    const load = (): void => {
        setLoading(true)
        tenantAdapter.find(login, pageable(pageNumber, pageSize, null, sort ? decodeURIComponent(sort.toString()) : null))
                .then(response => setElementsPage(response))
                .catch(error => setError(error.message))
                .finally(() => {
                    if (!initialSelectionSetFromId) {
                        console.debug("Setting selected row from id url param")
                        setSelectionModel(id ? [id] : [])
                        setInitialSelectionSetFromId(true)
                    }
                    setLoading(false)
                })
    }

    useHotkeys('a', () => {
        document.getElementById('add')!.click()
    })
    useHotkeys('e', () => {
        document.getElementById('edit')!.click()
    })
    useHotkeys('d,del', () => {
        document.getElementById('delete')!.click()
    })
    useEffect(() => { // load elements depending on the page params
        console.debug("Loading elements")
        load()
    }, [pageNumber, pageSize, sort])
    useEffect(() => { // update state of selected element
        const element = selectionModel.length == 1 ? elementsPage?.elements.find(it => it.id === selectionModel[0]) || null : null
        console.debug("Setting selected element and id", element)
        setSelectedElement(element)
        const elements = elementsPage?.elements.filter(it => selectionModel.includes(it.id)) || []
        console.debug("Setting selected elements", elements)
        setSelectedElements(elements)
        setId(element?.id)
    }, [selectionModel, elementsPage])

    const buttonBar = (<>
                <Tooltip title="Add (a)" placement="bottom">
                    <span><IconButton id="add" disabled={error != null}
                                      onClick={() => setCreateDialogOpen(true)}><AddSharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Edit (e)" placement="bottom">
                    <span><IconButton id="edit" disabled={error != null || selectionModel.length != 1}
                                      onClick={() => setEditDialogOpen(true)}><EditSharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Delete (d,del)" placement="bottom">
                    <span><IconButton id="delete" disabled={error != null || selectionModel.length < 1}
                                      onClick={() => setDeleteDialogOpen(true)}><DeleteSharp/></IconButton></span>
                </Tooltip>
                <TenantCreateDialog id="create-dialog"
                                    title={`Create ${props.topic}`}
                                    open={createDialogOpen === undefined || createDialogOpen === null ? false : createDialogOpen}
                                    login={login}
                                    onSubmitted={() => {
                                        setCreateDialogOpen(false)
                                        load()
                                    }}
                                    onClose={() => setCreateDialogOpen(false)}
                                    adapter={tenantAdapter}
                />
                <TenantEditDialog id="edit-dialog"
                                  title={`Edit ${props.topic}`}
                                  open={editDialogOpen === undefined || editDialogOpen === null ? false : editDialogOpen}
                                  element={selectedElement || emptyTenant}
                                  login={login}
                                  onSubmitted={() => {
                                      setEditDialogOpen(false)
                                      load()
                                  }}
                                  onClose={() => setEditDialogOpen(false)}
                                  adapter={tenantAdapter}
                />
                <TenantDeleteDialog id="delete-dialog"
                                    title={`Delete ${props.topic}`}
                                    open={deleteDialogOpen === undefined || deleteDialogOpen === null ? false : deleteDialogOpen}
                                    idsToDelete={selectedElements.map(e => e.id)}
                                    onClose={() => setDeleteDialogOpen(false)}
                                    onSubmitted={() => {
                                        setDeleteDialogOpen(false)
                                        load()
                                    }}
                                    login={login}
                                    adapter={tenantAdapter}
                />
            </>
    )

    return (
            <AuthenticatedLayout title={props.title}
                                 breadcrumbs={[{name: "Overview", link: "/app"}, {
                                     name: props.title,
                                     link: props.uri || "",
                                 }]}
                                 topRightSection={buttonBar}>
                {error != null ? (
                        <FlatPaper>
                            <Alert severity="error">
                                <AlertTitle>Failed to load elements</AlertTitle>
                                {error}
                            </Alert>
                        </FlatPaper>
                ) : (
                        <FlatPaper id="content" style={{height: '100%', minHeight: '650px'}}>
                            <DataGrid rows={elementsPage?.elements || []} columns={columns}
                                      checkboxSelection disableSelectionOnClick
                                      className="elements" getRowClassName={(() => "element")}
                                      pagination paginationMode="server" pageSize={pageSize || 0}
                                      rowsPerPageOptions={[10, 25, 50, 100]}
                                      rowCount={elementsPage?.totalElements || 0}
                                      onPageChange={(page => setPageNumber(page))}
                                      onPageSizeChange={(pageSize => setPageSize(pageSize))}
                                      sortingMode="server" sortModel={sortModel}
                                      onSortModelChange={(model => {
                                          setSortModel(model)
                                          setSort(assembleSort(model))
                                      })}
                                      selectionModel={selectionModel}
                                      onSelectionModelChange={model => setSelectionModel(model)}
                                      loading={isLoading}/>
                        </FlatPaper>
                )}
            </AuthenticatedLayout>
    )
}