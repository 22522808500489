import React from "react";
import {Box, Grid, Paper} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {Helmet} from "react-helmet";
import IllustrationImage from "../../images/illustration.svg";
import Logo from "../Logo";

export interface Props {
    title: string
    children: NonNullable<React.ReactNode>
}

export default function GeneralLayout(props: Props) {
    const theme = useTheme()
    return (
            <Grid container component="main" sx={{
                height: '100vh',
                backgroundImage: `url(${IllustrationImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundColor: theme.palette.background.default,
                backgroundSize: '50%',
                backgroundPosition: 'bottom right'
            }} justifyContent="center">
                <Helmet>
                    <title>{props.title} - AIOS</title>
                </Helmet>
                <Grid item xs={12} sm={8} md={3} component={Paper}>
                    <Box component="div" sx={{
                        margin: theme.spacing(8, 4),
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Logo marginBottom={2}/>
                        {props.children}
                    </Box>
                </Grid>
            </Grid>
    )
}
