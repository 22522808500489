import React, {useContext, useEffect, useState} from "react";
import {Box, Grid, Typography, useMediaQuery} from "@mui/material";
import AuthenticatedLayout from "../../components/layout/AuthenticatedLayout";
import {TenantContext} from "../provider/TenantProvider";
import ChartGrid from "../../components/grid/ChartGrid";
import {useTheme} from "@mui/material/styles";
import {permitsSuper} from "../../misc/authorization";
import {LoginContext} from "../provider/LoginProvider";
import ResourcesAllocatedBarChart, {Data} from "../../components/chart/ResourcesAllocatedBarChart";
import SingleValueChart from "../../components/chart/SingleValueChart";
import {metricsAdapter} from "../../adapters/interfaces";
import {RouteComponentProps} from "@reach/router";
import moment from "moment";

export interface Props extends RouteComponentProps {
    title: string
}

export default function (props: Props) {
    const theme = useTheme()
    const isSm = useMediaQuery(theme.breakpoints.down("md"))
    const {login} = useContext(LoginContext)
    const {tenant} = useContext(TenantContext)

    const [allTenantsCount, setAllTenantsCount] = useState<number[]>([])
    const [allUsersCount, setAllUsersCount] = useState<number[]>([])
    const [allApiKeysCount, setAllApiKeysCount] = useState<number[]>([])
    const [allDeploymentsCount, setAllDeploymentsCount] = useState<number[]>([])
    const [allCpu, setAllCpu] = useState<Data[]>([])
    const [allTotalCpu, setAllTotalCpu] = useState<number>(0)
    const [allMemory, setAllMemory] = useState<Data[]>([])
    const [allTotalMemory, setAllTotalMemory] = useState<number>(0)

    const [deploymentsCount, setDeploymentsCount] = useState<number[]>([])
    const [tenantCpu, setTenantCpu] = useState<Data[]>([])
    const [tenantTotalCpu, setTenantTotalCpu] = useState<number>(0)
    const [tenantMemory, setTenantMemory] = useState<Data[]>([])
    const [tenantTotalMemory, setTenantTotalMemory] = useState<number>(0)

    const now = new Date()
    useEffect(() => {
        if (permitsSuper(login)) {
            metricsAdapter.tenants(login, moment(now).subtract(30, "days").toDate(), now, 60 * 60 * 24)
                    .then(r => setAllTenantsCount(r.metrics.map(m => m.element.count)))
                    .catch(error => console.error("Error getting tenant metrics", error.message))
            metricsAdapter.users(login, moment(now).subtract(30, "days").toDate(), now, 60 * 60 * 24)
                    .then(r => setAllUsersCount(r.metrics.map(m => m.element.count)))
                    .catch(error => console.error("Error getting user metrics", error.message))
            metricsAdapter.apiKeys(login, moment(now).subtract(30, "days").toDate(), now, 60 * 60 * 24)
                    .then(r => setAllApiKeysCount(r.metrics.map(m => m.element.count)))
                    .catch(error => console.error("Error getting api key metrics", error.message))
            metricsAdapter.deployments(login, moment(now).subtract(30, "days").toDate(), now, 60 * 60 * 24)
                    .then(r => setAllDeploymentsCount(r.metrics.map(m => m.element.count)))
                    .catch(error => console.error("Error getting deployment metrics", error.message))
            metricsAdapter.resourcesAt(login, now)
                    .then(r => {
                        const allocatedCpu = r.cpu.requestedInMilliseconds / 1000
                        const limitedCpu = r.cpu.limitedInMilliseconds / 1000
                        const totalCpu = r.cpu.totalInMilliseconds / 1000
                        setAllCpu([
                            {
                                name: "Allocated",
                                value: allocatedCpu,
                                unit: " cores",
                                color: totalCpu == 0 || allocatedCpu / totalCpu <= 0.75 ? "primary" : allocatedCpu > totalCpu ? "error" : "alert"
                            },
                            {
                                name: "Limited",
                                value: limitedCpu,
                                unit: " cores",
                                color: totalCpu == 0 || limitedCpu / totalCpu <= 0.75 ? "primary" : limitedCpu > totalCpu ? "error" : "alert"
                            },
                        ])
                        setAllTotalCpu(totalCpu)
                        const allocatedMemory = r.memory.requestedInMegaBytes / 1000
                        const limitedMemory = r.memory.limitedInMegaBytes / 1000
                        const totalMemory = r.memory.totalInMegaBytes / 1000
                        setAllMemory([
                            {
                                name: "Allocated",
                                value: allocatedMemory,
                                unit: " cores",
                                color: totalMemory == 0 || allocatedMemory / totalMemory <= 0.75 ? "primary" : allocatedMemory > totalMemory ? "error" : "alert"
                            },
                            {
                                name: "Limited",
                                value: limitedMemory,
                                unit: " cores",
                                color: totalMemory == 0 || limitedMemory / totalMemory <= 0.75 ? "primary" : limitedMemory > totalMemory ? "error" : "alert"
                            },
                        ])
                        setAllTotalMemory(totalMemory)
                    })
                    .catch(error => console.error("Error getting resource metrics", error.message))
        }
    }, [])
    useEffect(() => {
        if (tenant) {
            const now = new Date()
            metricsAdapter.deployments(login, moment(now).subtract(30, "days").toDate(), now, 60 * 60 * 24, tenant.tenant.id)
                    .then(r => setDeploymentsCount(r.metrics.map(m => m.element.count)))
                    .catch(error => console.error("Error getting deployment metrics for tenant", error.message))
            metricsAdapter.resourcesAt(login, now, tenant.tenant.id)
                    .then(r => {
                        const allocatedCpu = r.cpu.requestedInMilliseconds / 1000
                        const limitedCpu = r.cpu.limitedInMilliseconds / 1000
                        const totalCpu = r.cpu.totalInMilliseconds / 1000
                        setTenantCpu([
                            {
                                name: "Allocated",
                                value: allocatedCpu,
                                unit: " cores",
                                color: totalCpu == 0 || allocatedCpu / totalCpu <= 0.75 ? "primary" : allocatedCpu > totalCpu ? "error" : "alert"
                            },
                            {
                                name: "Limited",
                                value: limitedCpu,
                                unit: " cores",
                                color: totalCpu == 0 || limitedCpu / totalCpu <= 0.75 ? "primary" : limitedCpu > totalCpu ? "error" : "alert"
                            },
                        ])
                        setTenantTotalCpu(totalCpu)
                        const allocatedMemory = r.memory.requestedInMegaBytes / 1000
                        const limitedMemory = r.memory.limitedInMegaBytes / 1000
                        const totalMemory = r.memory.totalInMegaBytes / 1000
                        setTenantMemory([
                            {
                                name: "Allocated",
                                value: allocatedMemory,
                                unit: " cores",
                                color: totalMemory == 0 || allocatedMemory / totalMemory <= 0.75 ? "primary" : allocatedMemory > totalMemory ? "error" : "alert"
                            },
                            {
                                name: "Limited",
                                value: limitedMemory,
                                unit: " cores",
                                color: totalMemory == 0 || limitedMemory / totalMemory <= 0.75 ? "primary" : limitedMemory > totalMemory ? "error" : "alert"
                            },
                        ])
                        setTenantTotalMemory(totalMemory)
                    })
                    .catch(error => console.error("Error getting resource metrics", error.message))
        }
    }, [tenant])

    return (<AuthenticatedLayout title={props.title} breadcrumbs={[{name: "Overview", link: props.uri || "/"}]}
                                 size="xl">
        <Box component="div" mt={1}>
            <Grid container spacing={2}>
                {permitsSuper(login) ? (<Grid container item spacing={1}>
                    <Grid item xs={12}><Typography component="span"
                                                   color="text.secondary">All tenants</Typography></Grid>
                    <Grid item xs={12}>
                        <ChartGrid id="all-tenants" layout={[
                            {i: "tenants", x: 0, y: 0, w: 3, h: 3, static: false},
                            {i: "users", x: 3, y: 0, w: 3, h: 3, static: false},
                            {i: "apikeys", x: 6, y: 0, w: 3, h: 3, static: false},
                            {i: "deployments", x: 9, y: 0, w: 3, h: 3, static: false},
                            {i: "cpu", x: 0, y: 3, w: 6, h: 9, static: false},
                            {i: "memory", x: 6, y: 3, w: 6, h: 9, static: false},
                        ]} layoutSm={[
                            {i: "tenants", x: 0, y: 0, w: 3, h: 3, static: false},
                            {i: "users", x: 3, y: 0, w: 3, h: 3, static: false},
                            {i: "apikeys", x: 0, y: 3, w: 3, h: 3, static: false},
                            {i: "deployments", x: 3, y: 3, w: 3, h: 3, static: false},
                            {i: "cpu", x: 0, y: 38, w: 6, h: 8, static: false},
                            {i: "memory", x: 0, y: 46, w: 6, h: 8, static: false},
                        ]} items={[
                            <div key="tenants">
                                <SingleValueChart id="all-tenants-tenants"
                                                  header="Tenants"
                                                  subHeader="last 30 days"
                                                  data={allTenantsCount}
                                                  height={isSm ? "50px" : "50px"}/>
                            </div>,
                            <div key="users">
                                <SingleValueChart id="all-tenants-users"
                                                  header="Users"
                                                  subHeader="last 30 days"
                                                  data={allUsersCount}
                                                  height={isSm ? "50px" : "50px"}/>
                            </div>,
                            <div key="apikeys">
                                <SingleValueChart id="all-tenants-apikeys"
                                                  header="API keys"
                                                  subHeader="last 30 days"
                                                  data={allApiKeysCount}
                                                  height={isSm ? "50px" : "50px"}/>
                            </div>,
                            <div key="deployments">
                                <SingleValueChart id="all-tenants-deployments"
                                                  header="Deployments"
                                                  subHeader="last 30 days"
                                                  data={allDeploymentsCount}
                                                  height={isSm ? "50px" : "50px"}/>
                            </div>,
                            <div key="cpu">
                                <ResourcesAllocatedBarChart id="all-tenants-cpu" header="CPU"
                                                            unit="Cores"
                                                            data={allCpu}
                                                            dataTotal={{
                                                                name: "Total",
                                                                value: allTotalCpu,
                                                                unit: " cores"
                                                            }}
                                                            height={isSm ? "200px" : "250px"}/>
                            </div>,
                            <div key="memory">
                                <ResourcesAllocatedBarChart id="all-tenants-memory" header="Memory"
                                                            unit="GB"
                                                            data={allMemory}
                                                            dataTotal={{
                                                                name: "Total",
                                                                value: allTotalMemory,
                                                                unit: " GB"
                                                            }}
                                                            height={isSm ? "200px" : "250px"}/>
                            </div>,
                        ]}/>
                    </Grid>
                </Grid>) : null}
                {tenant == null ? null : (<Grid item xs={12}>
                    <Grid container item spacing={1}>
                        <Grid item xs={12}><Typography component="span"
                                                       color="text.secondary">Tenant: {tenant.tenant.name}</Typography></Grid>
                        <Grid item xs={12}>
                            <ChartGrid id="tenant" layout={[
                                {i: "deployments", x: 0, y: 0, w: 12, h: 3, static: false},
                                {i: "cpu", x: 0, y: 3, w: 6, h: 9, static: false},
                                {i: "memory", x: 6, y: 12, w: 6, h: 9, static: false},
                            ]} layoutSm={[
                                {i: "deployments", x: 0, y: 0, w: 6, h: 3, static: false},
                                {i: "cpu", x: 0, y: 3, w: 6, h: 8, static: false},
                                {i: "memory", x: 0, y: 11, w: 6, h: 8, static: false},
                            ]} items={[
                                <div key="deployments">
                                    <SingleValueChart id="tenant-deployments"
                                                      header="Deployments"
                                                      subHeader="last 30 days"
                                                      data={deploymentsCount}
                                                      height={isSm ? "50px" : "50px"}/>
                                </div>,
                                <div key="cpu">
                                    <ResourcesAllocatedBarChart id="tenant-cpu" header="CPU"
                                                                unit="Cores"
                                                                data={tenantCpu}
                                                                dataTotal={{
                                                                    name: "Total",
                                                                    value: tenantTotalCpu,
                                                                    unit: " cores"
                                                                }}
                                                                height={isSm ? "200px" : "250px"}/>
                                </div>,
                                <div key="memory">
                                    <ResourcesAllocatedBarChart id="tenant-memory" header="Memory"
                                                                unit="GB"
                                                                data={tenantMemory}
                                                                dataTotal={{
                                                                    name: "Total",
                                                                    value: tenantTotalMemory,
                                                                    unit: " GB"
                                                                }}
                                                                height={isSm ? "200px" : "250px"}/>
                                </div>,
                            ]}/>
                        </Grid>
                    </Grid>
                </Grid>)}
            </Grid>
        </Box>
    </AuthenticatedLayout>)
}
