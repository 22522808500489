import {
    Alert,
    AlertTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    useMediaQuery
} from "@mui/material";
import React, {SyntheticEvent} from "react";
import {useTheme} from "@mui/material/styles";
import {LoadingButton} from "@mui/lab";

export interface Props {
    id: string
    title: string
    children: NonNullable<React.ReactNode>
    submitButton: string
    errorTitle: string
    error: string | null
    open?: boolean | null
    isLoading: boolean
    onClose: () => void
    onSubmit: (event: SyntheticEvent) => void
    fullScreen?: boolean
}

export default function (props: Props) {

    const theme = useTheme()

    return (
            <Dialog id={props.id} open={props.open || false} onClose={props.onClose}
                    fullScreen={props.fullScreen === undefined ? useMediaQuery(theme.breakpoints.down("sm")) : props.fullScreen}
                    aria-labelledby={`${props.id}-title`} transitionDuration={0}>
                <form id={`${props.id}-form`} method="post" onSubmit={props.onSubmit}>
                    <DialogTitle id={`${props.id}-title`}>{props.title}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} sx={{marginTop: '-8px'}}>
                            {props.error != null ? (
                                    <Grid item xs={12}>
                                        <DialogContentText component="div">
                                            <Alert id={`${props.id}-form-error`} severity="error">
                                                <AlertTitle>{props.errorTitle}</AlertTitle>
                                                {props.error}
                                            </Alert>
                                        </DialogContentText>
                                    </Grid>
                            ) : null}
                            {props.children}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button id={`${props.id}-cancel`} color="primary" onClick={props.onClose}>Cancel</Button>
                        <LoadingButton id={`${props.id}-submit`} color="primary" variant="contained" type="submit"
                                       loading={props.isLoading}>{props.submitButton}</LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
    )
}
