import React, {useContext, useEffect, useState} from "react";
import AuthenticatedLayout from "../../../components/layout/AuthenticatedLayout";
import {FlatPaper} from "../../Misc";
import DeploymentCreateDialog from "./DeploymentCreateDialog";
import {deploymentAdapter, Page, pageable, userAdapter} from "../../../adapters/interfaces";
import {LoginContext} from "../../provider/LoginProvider";
import {TenantContext} from "../../provider/TenantProvider";
import {BooleanParam, NumberParam, StringParam, useQueryParam} from "use-query-params";
import {CsvArrayParam} from "../../../misc/CsvArrayParam";
import {DataGrid, GridFilterModel, GridRowId, GridSortModel} from "@mui/x-data-grid";
import {DeploymentResponse} from "../../../generated/models/DeploymentResponse";
import {useHotkeys} from "react-hotkeys-hook";
import {Alert, AlertTitle, IconButton, Tooltip} from "@mui/material";
import {AddSharp, CompareArrowsSharp, ContentCopySharp, EditSharp, UpdateSharp} from "@mui/icons-material";
import {assembleFilter, assembleSort} from "../../../misc/misc";
import {columns, emptyDeployment} from "./DeploymentModel";
import DeploymentEditDialog from "./DeploymentEditDialog";
import DeploymentDiffDialog from "./DeploymentDiffDialog";
import {navigate} from "gatsby";
import {RouteComponentProps} from "@reach/router";

export interface Props extends RouteComponentProps {
    title: string
    topic: string
    sort?: string
}

export default function (props: Props) {
    const SORT_DEFAULT: string = props.sort ? encodeURIComponent(props.sort) : encodeURIComponent("-createdAt")

    const {login} = useContext(LoginContext)
    const {tenant} = useContext(TenantContext)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [pageNumber = 0, setPageNumber] = useQueryParam("page", NumberParam)
    const [pageSize = 10, setPageSize] = useQueryParam("size", NumberParam)
    const [sort = SORT_DEFAULT, setSort] = useQueryParam("sort", new CsvArrayParam())
    const [filter, setFilter] = useQueryParam("filter", StringParam)
    const [id, setId] = useQueryParam("id", StringParam)
    const [initialSelectionSetFromId, setInitialSelectionSetFromId] = useState<boolean>(false)
    const [cloneDialogOpen, setCloneDialogOpen] = useState<boolean>(false)
    const [compareDialogOpen, setCompareDialogOpen] = useState<boolean>(false)
    const [createDialogOpen, setCreateDialogOpen] = useQueryParam("create", BooleanParam)
    const [editDialogOpen, setEditDialogOpen] = useQueryParam("edit", BooleanParam)
    const [elementsPage, setElementsPage] = useState<Page<DeploymentResponse> | null>(null)
    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const [filterModel, setFilterModel] = useState<GridFilterModel | null>(null)
    const [selectionModel, setSelectionModel] = useState<GridRowId[]>([])
    const [selectedElement, setSelectedElement] = useState<DeploymentResponse | null>(null)
    const [selectedElements, setSelectedElements] = useState<DeploymentResponse[]>([])

    const load = (): void => {
        if (!tenant) return
        setLoading(true)
        const filterDef = filter ? `tenantId=="${tenant?.tenant?.id}";${filter}` : `tenantId=="${tenant?.tenant?.id}"`
        deploymentAdapter.find(login, pageable(pageNumber, pageSize, filterDef, sort ? decodeURIComponent(sort.toString()) : null))
                .then(response => setElementsPage(response))
                .catch(error => setError(error.message))
                .finally(() => {
                    if (!initialSelectionSetFromId) {
                        console.debug("Setting selected row from id url param")
                        setSelectionModel(id ? [id] : [])
                        setInitialSelectionSetFromId(true)
                    }
                    setLoading(false)
                })
    }

    useHotkeys('Shift + c', () => {
        document.getElementById('compare')!.click()
    })
    useHotkeys('c', () => {
        document.getElementById('clone')!.click()
    })
    useHotkeys('a', () => {
        document.getElementById('add')!.click()
    })
    useHotkeys('e', () => {
        document.getElementById('edit')!.click()
    })
    useHotkeys('v', () => {
        document.getElementById('versions')!.click()
    })
    useEffect(() => { // load elements depending on the page params
        console.debug("Loading elements")
        load()
    }, [pageNumber, pageSize, filter, sort])
    useEffect(() => { // update state of selected element
        const element = selectionModel.length == 1 ? elementsPage?.elements.find(it => it.id === selectionModel[0]) || null : null
        console.debug("Setting selected element and id", element)
        setSelectedElement(element)
        const elements = elementsPage?.elements.filter(it => selectionModel.includes(it.id)) || []
        console.debug("Setting selected elements", elements)
        setSelectedElements(elements)
        setId(element?.id)
    }, [selectionModel, elementsPage])

    const buttonBar = (<>
                <Tooltip title="Add (a)" placement="bottom">
                    <span><IconButton id="add" disabled={error != null}
                                      onClick={() => setCreateDialogOpen(true)}><AddSharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Clone (c)" placement="bottom">
                    <span><IconButton id="clone" disabled={error != null || selectionModel.length != 1}
                                      onClick={() => setCloneDialogOpen(true)}><ContentCopySharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Edit (e)" placement="bottom">
                    <span><IconButton id="edit" disabled={error != null || selectionModel.length != 1}
                                      onClick={() => setEditDialogOpen(true)}><EditSharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Compare (shift + c)" placement="bottom">
                    <span><IconButton id="compare" disabled={error != null || selectionModel.length != 2}
                                      onClick={() => setCompareDialogOpen(true)}><CompareArrowsSharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Versions (v)" placement="bottom">
                    <span><IconButton id="versions" disabled={error != null || selectionModel.length != 1}
                                      onClick={e => {
                                          e.preventDefault()
                                          navigate(`/app/deployments/${selectedElement?.id}/versions`)
                                      }}
                                      href={`${process.env.GATSBY_PATH_PREFIX}/deployments/${selectedElement?.id}/versions`}><UpdateSharp/></IconButton></span>
                </Tooltip>
                <DeploymentCreateDialog id="create-dialog"
                                        title={`Create ${props.topic}`}
                                        open={createDialogOpen === undefined || createDialogOpen === null ? false : createDialogOpen}
                                        login={login}
                                        tenant={tenant?.tenant || null}
                                        onSubmitted={() => {
                                            setCreateDialogOpen(false)
                                            load()
                                        }}
                                        onClose={() => setCreateDialogOpen(false)}
                                        adapter={deploymentAdapter}
                />
                <DeploymentCreateDialog id="clone-dialog"
                                        title={`Clone deployment ${selectedElement?.name || ""}`}
                                        open={cloneDialogOpen}
                                        login={login}
                                        tenant={tenant?.tenant || null}
                                        deployment={selectedElement || emptyDeployment}
                                        onSubmitted={() => {
                                            setCloneDialogOpen(false)
                                            load()
                                        }}
                                        onClose={() => setCloneDialogOpen(false)}
                                        adapter={deploymentAdapter}
                />
                <DeploymentEditDialog id="edit-dialog"
                                      title={`Edit deployment ${selectedElement?.name || ""}`}
                                      open={editDialogOpen === undefined || editDialogOpen === null ? false : editDialogOpen}
                                      login={login}
                                      tenant={tenant?.tenant || null}
                                      deployment={selectedElement || emptyDeployment}
                                      onSubmitted={() => {
                                          setEditDialogOpen(false)
                                          load()
                                      }}
                                      onClose={() => setEditDialogOpen(false)}
                                      adapter={deploymentAdapter}
                />
                <DeploymentDiffDialog id="compare-dialog"
                                      title={`Deployment diff - ${selectedElements.length == 2 ? selectedElements[1].name : ""} : ${selectedElements.length == 2 ? selectedElements[0].name : ""}`}
                                      open={compareDialogOpen}
                                      onClose={() => setCompareDialogOpen(false)}
                                      original={selectedElements.length == 2 ? selectedElements[1] : null}
                                      modified={selectedElements.length == 2 ? selectedElements[0] : null}
                />
            </>
    )

    return (
            <AuthenticatedLayout title={props.title}
                                 breadcrumbs={[{name: "Overview", link: "/app"}, {
                                     name: props.title,
                                     link: "/app/deployments",
                                 }]}
                                 topRightSection={buttonBar}>
                {error != null ? (
                        <FlatPaper>
                            <Alert severity="error">
                                <AlertTitle>Failed to load elements</AlertTitle>
                                {error}
                            </Alert>
                        </FlatPaper>
                ) : (
                        <FlatPaper id="content" style={{height: '100%', minHeight: '650px'}}>
                            <DataGrid rows={elementsPage?.elements || []} columns={columns(login, userAdapter)}
                                      checkboxSelection disableSelectionOnClick
                                      className="elements" getRowClassName={(() => "element")}
                                      pagination paginationMode="server" pageSize={pageSize || 0}
                                      rowsPerPageOptions={[10, 25, 50, 100]}
                                      rowCount={elementsPage?.totalElements || 0}
                                      onPageChange={(page => setPageNumber(page))}
                                      onPageSizeChange={(pageSize => setPageSize(pageSize))}
                                      filterMode="server" filterModel={filterModel || undefined}
                                      onFilterModelChange={(model => {
                                          setFilterModel(model)
                                          setFilter(assembleFilter(model))
                                      })}
                                      sortingMode="server" sortModel={sortModel}
                                      onSortModelChange={(model => {
                                          setSortModel(model)
                                          setSort(assembleSort(model))
                                      })}
                                      selectionModel={selectionModel}
                                      onSelectionModelChange={model => setSelectionModel(model)}
                                      loading={isLoading}/>
                        </FlatPaper>
                )}
            </AuthenticatedLayout>
    )
}
