import React, {SyntheticEvent, useState} from "react";
import FormDialog from "../../dialog/FormDialog";
import {LoginHolder} from "../../provider/LoginProvider";
import {ApiKeyResponse} from "../../../generated/models/ApiKeyResponse";
import {ApiKeyAdapter} from "../../../adapters/ApiKeyAdapter";
import ApiKeyEditForm from "./ApiKeyEditForm";
import {RoleResponse} from "../../../generated/models/RoleResponse";

export interface Props {
    id: string
    title: string
    open: boolean
    roles: RoleResponse[]
    element: ApiKeyResponse | null
    onClose?: () => void
    onSubmitted?: () => void
    adapter: ApiKeyAdapter
    login: LoginHolder | null
}

export default function (props: Props) {

    const onClose: () => void = () => {
        setLoading(false)
        setError(null)
        setDescriptionError(null)
        if (props.onClose) props.onClose()
    }

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [descriptionError, setDescriptionError] = useState<string | null>(null)

    const onSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setError(null)
        setDescriptionError(null)

        const data = new FormData(event.currentTarget as HTMLFormElement)
        const id = data.get("id") as string
        const description = data.get("description") as string
        props.adapter.updateOne(props.login, {id, description})
                .then(() => {
                    if (props.onSubmitted) props.onSubmitted()
                })
                .catch(error => setError(error.message)).finally(() => setLoading(false))
    }

    return (<FormDialog id={props.id} title={props.title} submitButton="Edit"
                        errorTitle="Failed to edit element." error={error} open={props.open}
                        isLoading={isLoading}
                        onClose={onClose} onSubmit={onSubmit}>
        <ApiKeyEditForm id={`${props.id}-form`}
                        roles={props.roles}
                        element={props.element}
                        descriptionError={descriptionError}
        />
    </FormDialog>)
}
