import {DeploymentResponse} from "../../../generated/models/DeploymentResponse";
import {getGridBooleanOperators, getGridStringOperators, GridCellParams, GridColDef} from "@mui/x-data-grid";
import {Box, Chip, IconButton, Tooltip} from "@mui/material";
import moment from "moment";
import React from "react";
import {CheckSharp, Visibility} from "@mui/icons-material";
import {navigate} from "gatsby";
import {UserAdapter} from "../../../adapters/UserAdapter";
import {LoginHolder} from "../../provider/LoginProvider";
import UserDisplayName from "../../UserDisplayName";
import {TenantResponse} from "../../../generated/models/TenantResponse";

export const ALIAS_REGEX: string = "^[a-z][a-z0-9-]{1,14}[a-z0-9]$"
export const IMAGE_NAME_REGEX: string = "^[a-z0-9][a-z0-9-\\/\\.]{0,254}[a-z0-9]$"
export const IMAGE_TAG_REGEX: string = "^[\\w][\\w.\\-]{0,127}$"
export const LABEL_NAME_REGEX: string = "^[a-z][a-z0-9\\-\\/\\.]{0,251}[a-z0-9]$"
export const LABEL_VALUE_REGEX: string = "^[a-zA-Z0-9]{0,1}[a-zA-Z0-9-_\\.]{0,61}[a-zA-Z0-9]{0,1}$"
export const VARIABLE_NAME_REGEX: string = "^[a-zA-Z_][a-zA-Z0-9_]{0,2047}$"
export const VARIABLE_VALUE_REGEX: string = ".*"
export const FILE_PATH_REGEX: string = "^[a-zA-Z0-9\\/_][a-zA-Z0-9\\/_\\-\\.]+[a-zA-Z0-9_]$"
export const URI_PATH_REGEX: string = "^[a-zA-Z0-9\\/_][a-zA-Z0-9\\/_\\-\\.]*[a-zA-Z0-9_]*$"
export const USERNAME_REGEX: string = "^.{1,100}$"
export const PASSWORD_REGEX: string = "^.{8,100}$"
export const DOMAIN_REGEX: string = "^[a-z][a-z0-9-\\/\\.]{1,251}[a-z0-9]$"

export const buildDeploymentUrl = (deployment: DeploymentResponse, tenant: TenantResponse, rootUrl: string): string | null => {
    const reachability = deployment.specification.reachability;
    if (reachability && reachability.isPublic) {
        if (reachability.domain) {
            return `${reachability.domainUseTls ? "https:" : "http:"}//${reachability.domain}/${tenant.alias}/${deployment.alias}`
        } else {
            return `${location.protocol}${rootUrl}/${tenant.alias}/${deployment.alias}`
        }
    }
    return null
}

export const emptyDeployment: DeploymentResponse = {
    id: "",
    version: 0,
    tenantId: "",
    enabled: true,
    alias: "",
    name: "",
    description: "",
    specification: {
        image: {
            name: "",
            tag: "",
            ports: [],
        },
        resources: {
            replicas: 1,
            cpuMinInMilliseconds: 100,
            cpuMaxInMilliseconds: 1000,
            memoryMinInMegaBytes: 128,
            memoryMaxInMegaBytes: 1024,
            gpu: 0,
            runRestrictions: [],
        },
        environment: {
            variables: [],
            configFiles: [],
        },
        endpoints: {
            health: {path: "/health", port: 8080},
            ready: {path: "/health", port: 8080},
            started: {path: "/health", port: 8080},
            openApi: {path: "/openapi.json", port: 8080},
        },
        telemetry: {
            metricsEndpoint: {path: "/metrics", port: 8080},
            metricsToRecordAsRegex: [],
        },
        reachability: {
            isPublic: false,
        },
        tags: [],
    },
    userId: "",
    createdAt: new Date(0),
}

export const deploymentSchema = {
    "$schema": "https://json-schema.org/draft-07/schema", // this version is supported by the code editor
    "$id": "/schemas/deployment",
    "title": "Deployment",
    "description": "The deployment in AIOS.",
    "type": "object",
    "required": [
        "enabled",
        "alias",
        "name",
        "specification",
    ],
    "properties": {
        "enabled": {
            "description": "If enabled, the deployment is actually deployed.",
            "type": "boolean",
        },
        "alias": {
            "description": "The alias is used to provide unique representation. It cannot be changed after the deployment has been created.",
            "type": "string",
            "pattern": ALIAS_REGEX,
        },
        "name": {
            "description": "A human readable name.",
            "type": "string",
            "pattern": ".{2,50}",
        },
        "specification": {
            "$ref": "/schemas/deployment-specification"
        },
    }
}

export const deploymentSpecificationSchema = {
    "$schema": "https://json-schema.org/draft-07/schema", // this version is supported by the code editor
    "$id": "/schemas/deployment-specification",
    "title": "Deployment Specification",
    "description": "The specification of a deployment.",
    "type": "object",
    "required": [
        "image",
        "resources",
        "environment",
        "endpoints",
        "reachability",
        "tags"
    ],
    "properties": {
        "image": {
            "$ref": "#/$defs/DeploymentImage"
        },
        "resources": {
            "$ref": "#/$defs/DeploymentResources"
        },
        "environment": {
            "$ref": "#/$defs/DeploymentEnvironment"
        },
        "endpoints": {
            "$ref": "#/$defs/DeploymentEndpoints"
        },
        "telemetry": {
            "$ref": "#/$defs/DeploymentTelemetry"
        },
        "reachability": {
            "$ref": "#/$defs/DeploymentReachability"
        },
        "tags": {
            "description": "Tags to group several deployments and other resources together.",
            "type": "array",
            "items": {
                "type": "string"
            }
        }
    },
    "$defs": {
        "DeploymentLabel": {
            "description": "The label definition.",
            "type": "object",
            "required": [
                "name",
                "value"
            ],
            "properties": {
                "name": {
                    "description": "The label name.",
                    "type": "string",
                    "pattern": LABEL_NAME_REGEX,
                },
                "value": {
                    "description": "The label value.",
                    "type": "string",
                    "pattern": LABEL_VALUE_REGEX,
                }
            }
        },
        "DeploymentImage": {
            "description": "The image definition.",
            "type": "object",
            "required": [
                "name",
                "tag",
                "ports"
            ],
            "properties": {
                "name": {
                    "description": "The container image name.",
                    "type": "string",
                    "pattern": IMAGE_NAME_REGEX,
                },
                "tag": {
                    "description": "The container image tag.",
                    "type": "string",
                    "pattern": IMAGE_TAG_REGEX,
                },
                "ports": {
                    "description": "The ports to open for a container.",
                    "type": "array",
                    "items": {
                        "type": "integer",
                        "minimum": 1,
                        "maximum": 65535
                    }
                }
            }
        },
        "DeploymentResources": {
            "type": "object",
            "required": [
                "replicas",
                "cpuMinInMilliseconds",
                "cpuMaxInMilliseconds",
                "memoryMinInMegaBytes",
                "memoryMaxInMegaBytes",
                "gpu"
            ],
            "properties": {
                "replicas": {
                    "description": "The amount of instances to be started.",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 9999
                },
                "cpuMinInMilliseconds": {
                    "description": "The minimum CPUs to assign to the container in milli CPUs.",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 512000
                },
                "cpuMaxInMilliseconds": {
                    "description": "The maximum CPUs to assign to the container in milli CPUs.",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 512000
                },
                "memoryMinInMegaBytes": {
                    "description": "The minimum memory to assign to the container in MB (1 mega byte is 1000 kilo bytes).",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 2147483647
                },
                "memoryMaxInMegaBytes": {
                    "description": "The maximum memory to assign to the container in MB (1 mega byte is 1000 kilo bytes).",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 2147483647
                },
                "gpu": {
                    "description": "The amount of GPUs to be assigned to the container.",
                    "type": "integer",
                    "minimum": 0,
                    "maximum": 9999
                },
                "runRestrictions": {
                    "description": "Defines the restrictions on which nodes the container is allowed to run.",
                    "type": "array",
                    "items": {
                        "$ref": "#/$defs/DeploymentLabel"
                    }
                }
            }
        },
        "DeploymentVariable": {
            "description": "The variable definition.",
            "type": "object",
            "required": [
                "name",
                "value",
                "encrypted"
            ],
            "properties": {
                "name": {
                    "description": "The variable name.",
                    "type": "string",
                    "pattern": VARIABLE_NAME_REGEX,
                },
                "value": {
                    "description": "The variable value.",
                    "type": "string",
                    "pattern": VARIABLE_VALUE_REGEX,
                },
                "encrypted": {
                    "description": "Whether the value is encrypted or not. When changing an encrypted value, the value will be encrypted when the data is saved.",
                    "type": "boolean"
                }
            }
        },
        "DeploymentFile": {
            "description": "The file definition.",
            "type": "object",
            "required": [
                "name",
                "content"
            ],
            "properties": {
                "name": {
                    "description": "The file name including the path. A relative path will be mounted in the working directory in the container.",
                    "type": "string",
                    "pattern": FILE_PATH_REGEX
                },
                "content": {
                    "description": "The file content as a base64 string (not the URL encoding).",
                    "type": "string",
                    "contentEncoding": "base64"
                }
            }
        },
        "DeploymentEnvironment": {
            "description": "The environment definition.",
            "type": "object",
            "required": [
                "variables",
                "configFiles"
            ],
            "properties": {
                "variables": {
                    "description": "The environment variables.",
                    "type": "array",
                    "items": {
                        "$ref": "#/$defs/DeploymentVariable"
                    }
                },
                "configFiles": {
                    "description": "The files to mount into a container.",
                    "type": "array",
                    "items": {
                        "$ref": "#/$defs/DeploymentFile"
                    }
                }
            }
        },
        "DeploymentEndpoint": {
            "description": "The endpoint definition.",
            "type": "object",
            "required": [
                "path",
                "port"
            ],
            "properties": {
                "path": {
                    "description": "The endpoint path.",
                    "type": "string",
                    "pattern": URI_PATH_REGEX
                },
                "port": {
                    "description": "The endpoint port.",
                    "type": "integer",
                    "minimum": 1,
                    "maximum": 65535
                }
            }
        },
        "DeploymentEndpoints": {
            "description": "The endpoints definition.",
            "type": "object",
            "required": [
                "health",
                "ready",
                "started",
                "openApi"
            ],
            "properties": {
                "health": {
                    "description": "The health endpoint to determine whether a container is healthy or not.",
                    "$ref": "#/$defs/DeploymentEndpoint"
                },
                "ready": {
                    "description": "The ready endpoint to determine whether a container is ready or not.",
                    "$ref": "#/$defs/DeploymentEndpoint"
                },
                "started": {
                    "description": "The started endpoint to determine whether a container is started or not.",
                    "$ref": "#/$defs/DeploymentEndpoint"
                },
                "openApi": {
                    "$ref": "#/$defs/DeploymentEndpoint"
                }
            }
        },
        "DeploymentTelemetry": {
            "description": "The telemetry definition to enable observability.",
            "type": "object",
            "required": [
                "metricsEndpoint",
                "metricsToRecordAsRegex",
            ],
            "properties": {
                "metricsEndpoint": {
                    "description": "The metrics endpoint where Prometheus is scraping the metrics.",
                    "$ref": "#/$defs/DeploymentEndpoint"
                },
                "metricsToRecordAsRegex": {
                    "description": "The metrics to keep when the container is scraped by Prometheus.",
                    "type": "array",
                    "items": {
                        "type": "string",
                    }
                },
            }
        },
        "DeploymentReachabilitySecurity": {
            "description": "The basic authentication definition for a container exposed publicly.",
            "type": "object",
            "required": [
                "useBasicAuth"
            ],
            "properties": {
                "useBasicAuth": {
                    "description": "Whether to use basic auth for the container or not.",
                    "type": "boolean"
                },
                "basicAuthUsername": {
                    "description": "The username of the basic authentication.",
                    "type": ["string", "null"],
                },
                "basicAuthPassword": {
                    "description": "The password of the basic authentication.",
                    "type": ["string", "null"],
                }
            }
        },
        "DeploymentReachability": {
            "description": "The definition if and how the container is reachable from the outside.",
            "type": "object",
            "required": [
                "isPublic"
            ],
            "properties": {
                "isPublic": {
                    "description": "Whether the container is reachable from the outside or not.",
                    "type": "boolean"
                },
                "domain": {
                    "description": "If the domain is set, then the container will be reachable with that domain. A DNS entry must be set accordingly.",
                    "type": ["string", "null"],
                },
                "domainUseTls": {
                    "description": "If set to true, then the container will be available through HTTPS.",
                    "type": "boolean",
                },
                "security": {
                    "$ref": "#/$defs/DeploymentReachabilitySecurity"
                }
            }
        },
    }
}

export const columns: (login: LoginHolder | null, userAdapter: UserAdapter) => GridColDef[] = (login, userAdapter) => {
    return [
        {
            field: "id", headerName: "ID", minWidth: 100, filterable: true,
            filterOperators: getGridStringOperators().filter(o => o.value === "equals"),
            sortable: true,
            renderCell: (params: GridCellParams) => {
                if (!params.value) return null
                return (<Tooltip title={params.value}><Box overflow="hidden"
                                                           textOverflow="ellipsis">{params.value}</Box></Tooltip>)
            },
        },
        {
            field: "version",
            headerName: "Version",
            minWidth: 50,
            width: 75,
            filterable: false,
            sortable: false,
            type: "number"
        },
        {
            field: "enabled", headerName: "Enabled", width: 80, filterable: true,
            filterOperators: getGridBooleanOperators().filter(o => o.value === "is"),
            sortable: true,
            renderCell: (params: GridCellParams) => {
                return params.value ? (<CheckSharp/>) : ""
            }, type: "boolean",
        },
        {
            field: "alias",
            headerName: "Alias",
            minWidth: 100,
            width: 200,
            filterable: true,
            filterOperators: getGridStringOperators().filter(o => o.value === "equals"),
            sortable: true,
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 120,
            flex: 1,
            filterable: true,
            filterOperators: getGridStringOperators().filter(o => o.value === "equals"),
            sortable: true,
        },
        {
            field: "userId",
            headerName: "User",
            minWidth: 100,
            width: 120,
            filterable: false,
            sortable: false,
            renderCell: (params: GridCellParams) => {
                return (<UserDisplayName userId={params.value} login={login} adapter={userAdapter}/>)
            }
        },
        {
            field: "createdAt", headerName: "Modified", minWidth: 140, width: 220, filterable: false,
            sortable: true,
            renderCell: (params: GridCellParams) => {
                if (!params.value) return null
                const timestamp = moment(params.value)
                return (<Box component="span"><Tooltip title={timestamp.local().format()}><Chip
                        label={timestamp.fromNow()}/></Tooltip></Box>)
            }, type: "dateTime",
        },
        {
            field: "open", headerName: "", width: 75, filterable: false, sortable: false,
            renderCell: (params: GridCellParams) => {
                return (<IconButton onClick={e => {
                    e.preventDefault()
                    navigate(`/deployments/${params.id}`)
                }} href={`${process.env.GATSBY_PATH_PREFIX}/deployments/${params.id}`}><Visibility/></IconButton>)
            }, type: "actions",
        },
    ]
}