import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import TextInput from "../../form/TextInput";
import {ALIAS_REGEX} from "./TenantModel";

export interface Props {
    id: string

    nameError: string | null
    aliasError: string | null
}

export default function (props: Props) {
    const [nameError, setNameError] = useState<string | null>(props.nameError)
    const [aliasError, setAliasError] = useState<string | null>(props.aliasError)

    useEffect(() => setNameError(props.nameError), [props.nameError])
    useEffect(() => setAliasError(props.aliasError), [props.aliasError])

    return (<>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-name`} label="Name" name="name" error={nameError}
                       required={false} helpMessage="The name of the tenant."/>
        </Grid>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-alias`} label="Alias" name="alias" error={aliasError} pattern={ALIAS_REGEX}
                       required={false} maxLength={16}
                       helpMessage="The alias for the tenant. Must be a valid DNS name and cannot be changed later."/>
        </Grid>
    </>)
}
