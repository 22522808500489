import React, {SyntheticEvent, useContext, useEffect, useState} from "react";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import AuthenticatedLayout from "../../components/layout/AuthenticatedLayout";
import {EditSharp} from "@mui/icons-material";
import FormDialog from "../../components/dialog/FormDialog";
import {FlatPaper} from "../Misc";
import {LoginContext} from "../provider/LoginProvider";
import {SettingDefinitionResponse} from "../../generated/models/SettingDefinitionResponse";
import {SettingResponse} from "../../generated/models/SettingResponse";
import {globalSettingAdapter, Page, PageableRequest} from "../../adapters/interfaces";
import {RouteComponentProps} from "@reach/router";

export interface Props extends RouteComponentProps {
    title: string
}

export default function (props: Props) {
    const {login} = useContext(LoginContext)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [settingsDefs, setSettingsDefs] = useState<SettingDefinitionResponse[]>([])
    const [settingsPage, setSettingsPage] = useState<Page<SettingResponse> | null>(null)
    const [formError, setFormError] = useState<string | null>(null)
    const [formValueError, setFormValueError] = useState<string | null>(null)
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)
    const [isLoadingEditDialog, setLoadingEditDialog] = useState<boolean>(false)
    const [editSetting, setEditSetting] = useState<SettingDefinitionResponse | null>(null)
    const [editSettingValue, setEditSettingValue] = useState<string | null>(null)

    const loadSettings = (): Promise<void> => {
        setLoading(true)
        return globalSettingAdapter.findDefinitions(login, new PageableRequest())
                .then((response) => {
                    setSettingsDefs(response.elements)
                    return globalSettingAdapter.find(login, new PageableRequest(0, 1000, null, "+name"))
                })
                .then((response) => {
                    setSettingsPage(response)
                })
                .catch(error => setError(error.message))
                .finally(() => setLoading(false))
    }

    const updateSetting = (name: string, value: string | null): Promise<any> => {
        if (value == null) {
            return globalSettingAdapter.deleteOne(login, name).then(() => loadSettings())
        } else if (settingsPage?.elements.find(s => s.name === name)) {
            return globalSettingAdapter.updateOne(login, {name, value}).then(() => loadSettings())
        } else {
            return globalSettingAdapter.createOne(login, {name, value}).then(() => loadSettings())
        }
    }

    const handleSettingEdit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoadingEditDialog(true)
        setFormError(null)
        setFormValueError(null)
        const target = event.target as typeof event.target & {
            name: { value: string }
            value: { value: string }
        }
        updateSetting(target.name.value, target.value.value)
                .then(() => {
                    setEditDialogOpen(false)
                    setEditSettingValue(null)
                    setEditSetting(null)
                    setFormValueError(null)
                    setFormError(null)
                })
                .catch(error => setFormError(error.message))
                .finally(() => setLoadingEditDialog(false))
    }

    useEffect(() => {
        // loading is for not loading multiple times
        if (!isLoading && settingsPage == null) {
            loadSettings()
        }
    })

    return (<AuthenticatedLayout title={props.title}
                                 breadcrumbs={[{name: "Overview", link: "/app"}, {
                                     name: "Settings",
                                     link: props.uri || ""
                                 }]}>
        {error != null ? (
                <FlatPaper>
                    <Alert severity="error">
                        <AlertTitle>Failed to load elements</AlertTitle>
                        {error}
                    </Alert>
                </FlatPaper>
        ) : (
                <Grid container spacing={2} alignItems="stretch">
                    {settingsDefs.map(d => {
                        const value = settingsPage?.elements.find(s => s.name === d.name)?.value
                        return (
                                <Grid key={d.name} item xs={12} sm={4}>
                                    <Card variant="outlined" id={`setting-${d.name}`}>
                                        <CardContent>
                                            <Typography color="textSecondary" gutterBottom>
                                                <Box fontFamily="Fira Code, monospace" component="span">{d.name}</Box>
                                            </Typography>
                                            <Typography variant="h5" component="h2">{d.displayName}</Typography>
                                            <Typography color="textSecondary">
                                                Current: <Box fontFamily="Fira Code, monospace" component="span">
                                                {value || '[not set]'}
                                            </Box>
                                            </Typography>
                                            <Typography color="textSecondary">
                                                Default: <Box fontFamily="Fira Code, monospace"
                                                              component="span">{d._default}</Box>
                                            </Typography>
                                            <Typography variant="body2" component="p">
                                                {d.help}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button id={`setting-edit-${d.name}`} size="small" startIcon={<EditSharp/>}
                                                    onClick={() => {
                                                        setEditSetting(d)
                                                        setEditSettingValue(value || null)
                                                        setEditDialogOpen(true)
                                                    }}>Edit</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                        )
                    })}
                    <FormDialog id="edit-dialog" title={editSetting?.displayName || ''} submitButton="Edit"
                                errorTitle="Failed to edit element." error={formError} open={editDialogOpen}
                                isLoading={isLoadingEditDialog}
                                onClose={() => {
                                    setEditDialogOpen(false)
                                    setEditSettingValue(null)
                                    setEditSetting(null)
                                    setFormValueError(null)
                                    setFormError(null)
                                }} onSubmit={handleSettingEdit}>
                        <Grid item xs={12}>
                            <input type="hidden" name="name" value={editSetting?.name || ''}/>
                            <TextField id={`form-${editSetting?.name}`} name="value" label={editSetting?.name}
                                       error={formValueError != null} required
                                       variant="outlined" fullWidth autoFocus
                                       InputLabelProps={{shrink: true}}
                                       inputProps={{pattern: editSetting?.regex}}
                                       helperText={formValueError ? `${editSetting?.help} ${formValueError}` : editSetting?.help}
                                       placeholder={editSetting?._default}
                                       onInvalid={event => {
                                           const target = event.target as typeof event.target & {
                                               pattern: string
                                           }
                                           setFormValueError(`Value must match pattern ${target.pattern}`)
                                       }}
                                       defaultValue={editSettingValue}/>
                        </Grid>
                    </FormDialog>
                </Grid>
        )}
    </AuthenticatedLayout>)
}
