import {decodeDelimitedArray, encodeDelimitedArray, QueryParamConfig} from 'use-query-params';

export class CsvArrayParam implements QueryParamConfig<string[], string | (string | null)[] | null | undefined> {
    private SEPARATOR = ",";

    decode(value: string | (string | null)[] | null | undefined): string | (string | null)[] | null | undefined {
        return decodeDelimitedArray(value, this.SEPARATOR);
    }

    encode(value: string[]): string | (string | null)[] | null | undefined {
        return encodeDelimitedArray(value, this.SEPARATOR);
    }
}