import {
    Alert,
    AlertTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery
} from "@mui/material";
import {WarningButton} from "../Buttons";
import React from "react";
import {useTheme} from "@mui/material/styles";

export interface Props {
    title: string
    children: NonNullable<React.ReactNode>
    errorTitle: string
    error: string | null
    open?: boolean | null
    isLoading: boolean
    onClose: () => void
    onSubmit: () => void
    fullScreen?: boolean
}

export default function (props: Props) {

    const theme = useTheme()

    return (
            <Dialog id="delete-dialog" open={props.open || false} onClose={props.onClose}
                    fullScreen={props.fullScreen === undefined ? useMediaQuery(theme.breakpoints.down("sm")) : props.fullScreen}
                    aria-labelledby="delete-dialog-title" transitionDuration={0}>
                <DialogTitle id="delete-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    {props.error != null ? (
                            <DialogContentText component="div">
                                <Alert id="delete-dialog-form-error" severity="error">
                                    <AlertTitle>{props.errorTitle}</AlertTitle>
                                    {props.error}
                                </Alert>
                            </DialogContentText>
                    ) : null}
                    <DialogContentText component="div">{props.children}</DialogContentText>
                    <DialogActions>
                        <Button id="delete-dialog-cancel" color="primary" onClick={props.onClose}>Cancel</Button>
                        <WarningButton id="delete-dialog-submit" variant="contained" autoFocus
                                       onClick={props.onSubmit} loading={props.isLoading}>Delete</WarningButton>
                    </DialogActions>
                </DialogContent>
            </Dialog>
    )
}
