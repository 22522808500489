import React from "react";
import {Box, Link} from "@mui/material";
import {SentimentVeryDissatisfiedSharp} from "@mui/icons-material";
import GeneralLayout from "../layout/GeneralLayout";
import {RouteComponentProps} from "@reach/router";

export interface Props extends RouteComponentProps {
}

export default function (props: Props) {
    console.debug(`Path ${props.uri} not found`)
    return (
            <GeneralLayout title="Not found">
                <Box m={2}>There is no page with this URL</Box>
                <Box m={2}><SentimentVeryDissatisfiedSharp style={{fontSize: 100}}/></Box>
                <Box m={2}>
                    <Link href={`${process.env.GATSBY_PATH_PREFIX}/`} variant="body2">
                        Back to the overview
                    </Link>
                </Box>
            </GeneralLayout>
    );
}
