import React, {useContext} from "react";
import "swagger-ui-react/swagger-ui.css";
import {UiConfigContext} from "../provider/UiConfigProvider";
import SwaggerUI from "swagger-ui-react";
import {Helmet} from "react-helmet";
import {RouteComponentProps} from "@reach/router";

export interface Props extends RouteComponentProps {
    title: string
}

export default function (props: Props) {
    const {uiConfig} = useContext(UiConfigContext)
    return (<div>
        <Helmet><title>{props.title} - AIOS</title></Helmet>
        <SwaggerUI url={uiConfig?.openApiSpecUrl} persistAuthorization={true}/>
    </div>)
}
