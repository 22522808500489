import React, {useContext, useEffect, useState} from "react";
import AuthenticatedLayout from "../../../components/layout/AuthenticatedLayout";
import {LoginContext} from "../../provider/LoginProvider";
import {DeploymentResponse} from "../../../generated/models/DeploymentResponse";
import {deploymentAdapter} from "../../../adapters/interfaces";
import {
    Box,
    CircularProgress,
    Grid,
    GridProps,
    IconButton,
    Link,
    styled,
    Switch,
    Tooltip,
    Typography
} from "@mui/material";
import {ContentCopySharp, EditSharp, UpdateSharp} from "@mui/icons-material";
import {BooleanParam, useQueryParam} from "use-query-params";
import Card from "../../Card";
import moment from "moment";
import {UiConfigContext} from "../../provider/UiConfigProvider";
import {TenantContext} from "../../provider/TenantProvider";
import {useHotkeys} from "react-hotkeys-hook";
import {navigate} from "gatsby";
import DeploymentCreateDialog from "./DeploymentCreateDialog";
import {buildDeploymentUrl, emptyDeployment} from "./DeploymentModel";
import DeploymentEditDialog from "./DeploymentEditDialog";
import {DeploymentStatus, DeploymentStatusFromJSON} from "../../../generated/models/DeploymentStatus";
import {RouteComponentProps} from "@reach/router";

export interface Props extends RouteComponentProps {
    deploymentId?: string
}

export default function (props: Props) {
    const {uiConfig} = useContext(UiConfigContext)
    const {login} = useContext(LoginContext)
    const {tenant} = useContext(TenantContext)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [cloneDialogOpen, setCloneDialogOpen] = useState<boolean>(false)
    const [editDialogOpen, setEditDialogOpen] = useQueryParam("edit", BooleanParam)
    const [element, setElement] = useState<DeploymentResponse | null>(null)
    const [elementUrl, setElementUrl] = useState<string | null>(null)
    const [status, setStatus] = useState<DeploymentStatus>(DeploymentStatusFromJSON({
        status: "NOT RUNNING"
    }))

    const GridBold = styled(Grid)<GridProps>(() => ({
        fontWeight: "bold",
    }))

    const load = (): void => {
        if (!props.deploymentId) return
        setLoading(true)
        deploymentAdapter.get(login, props.deploymentId)
                .then(response => setElement(response))
                .catch(error => setError(error.message))
                .finally(() => {
                    setLoading(false)
                })
    }

    useHotkeys('c', () => {
        document.getElementById('clone')!.click()
    })
    useHotkeys('e', () => {
        document.getElementById('edit')!.click()
    })
    useHotkeys('v', () => {
        document.getElementById('versions')!.click()
    })
    useEffect(() => {
        console.debug("Loading element")
        load()
    }, [])
    useEffect(() => {
        if (element && tenant && uiConfig) {
            const deploymentUrl = buildDeploymentUrl(element, tenant.tenant, uiConfig.rootUrl);
            setElementUrl(deploymentUrl)
        }
    }, [element, tenant, uiConfig])
    useEffect(() => {
        if (props.deploymentId && element?.enabled) {
            deploymentAdapter.status(login, props.deploymentId)
                    .then(status => setStatus(status))
                    .catch(error => setError(error.message))
        }
    }, [element])

    const buttonBar = (<>
                <Tooltip title="Clone (c)" placement="bottom">
                    <span><IconButton id="clone" disabled={error != null}
                                      onClick={() => setCloneDialogOpen(true)}><ContentCopySharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Edit (e)" placement="bottom">
                    <span><IconButton id="edit" disabled={error != null}
                                      onClick={() => setEditDialogOpen(true)}><EditSharp/></IconButton></span>
                </Tooltip>
                <Tooltip title="Versions (v)" placement="bottom">
                    <span><IconButton id="versions" disabled={error != null}
                                      onClick={e => {
                                          e.preventDefault()
                                          navigate(`/app/deployments/${props.deploymentId}/versions`)
                                      }}
                                      href={`${process.env.GATSBY_PATH_PREFIX}/deployments/${props.deploymentId}/versions`}><UpdateSharp/></IconButton></span>
                </Tooltip>
                <DeploymentCreateDialog id="clone-dialog"
                                        title={`Clone deployment ${element?.name || ""}`}
                                        open={cloneDialogOpen}
                                        login={login}
                                        tenant={tenant?.tenant || null}
                                        deployment={element || emptyDeployment}
                                        onSubmitted={() => {
                                            setCloneDialogOpen(false)
                                        }}
                                        onClose={() => setCloneDialogOpen(false)}
                                        adapter={deploymentAdapter}
                />
                <DeploymentEditDialog id="edit-dialog"
                                      title={`Edit deployment ${element?.name || ""}`}
                                      open={editDialogOpen === undefined || editDialogOpen === null ? false : editDialogOpen}
                                      login={login}
                                      tenant={tenant?.tenant || null}
                                      deployment={element || emptyDeployment}
                                      onSubmitted={() => {
                                          setEditDialogOpen(false)
                                          load()
                                      }}
                                      onClose={() => setEditDialogOpen(false)}
                                      adapter={deploymentAdapter}
                />
            </>
    )

    return (
            <AuthenticatedLayout title={`Deployment ${element?.name || "loading..."}`}
                                 size="xl" topRightSection={buttonBar}
                                 breadcrumbs={[{name: "Overview", link: "/app"}, {
                                     name: "Deployments",
                                     link: "/deployments",
                                 }, {
                                     name: element?.name || "Loading...",
                                     link: `/deployments/${props.deploymentId}`,
                                 }]}>
                {isLoading ? (<Box component="div"
                                   display="flex"
                                   justifyContent="center"
                                   alignItems="center"
                                   sx={{width: "100%", height: "100%"}}>
                    <CircularProgress/>
                </Box>) : <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card header="Fundamentals">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Switch disabled
                                            checked={element?.enabled || false}/> {element?.enabled ? "enabled" : "disabled"}
                                </Grid>
                                <GridBold item xs={3}>ID:</GridBold>
                                <Grid item xs={9}>{element?.id}</Grid>
                                <GridBold item xs={3}>Version:</GridBold>
                                <Grid item xs={9}>{element?.version}</Grid>
                                <GridBold item xs={3}>Alias:</GridBold>
                                <Grid item xs={9}>{element?.alias}</Grid>
                                <GridBold item xs={3}>Name:</GridBold>
                                <Grid item xs={9}>{element?.name}</Grid>
                                <GridBold item xs={3}>Description:</GridBold>
                                <Grid item xs={9}>{element?.description}</Grid>
                                <GridBold item xs={3}>Timestamp:</GridBold>
                                <Grid item xs={9}>{moment(element?.createdAt).fromNow()}</Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card header="Resources">
                            <Grid container>
                                <GridBold item xs={4}>Replicas:</GridBold>
                                <Grid item xs={8}>{element?.specification?.resources?.replicas}</Grid>
                                <GridBold item xs={4}>CPU (min):</GridBold>
                                <Grid item xs={8}>{element?.specification?.resources?.cpuMinInMilliseconds}m</Grid>
                                <GridBold item xs={4}>CPU (max):</GridBold>
                                <Grid item xs={8}>{element?.specification?.resources?.cpuMaxInMilliseconds}m</Grid>
                                <GridBold item xs={4}>Memory (min):</GridBold>
                                <Grid item xs={8}>{element?.specification?.resources?.memoryMinInMegaBytes} MB</Grid>
                                <GridBold item xs={4}>Memory (max):</GridBold>
                                <Grid item xs={8}>{element?.specification?.resources?.memoryMaxInMegaBytes} MB</Grid>
                                <GridBold item xs={4}>GPUs:</GridBold>
                                <Grid item xs={8}>{element?.specification?.resources?.gpu}</Grid>
                                <GridBold item xs={12}>Run restrictions:</GridBold>
                                {(element?.specification?.resources?.runRestrictions)?.map(it => (
                                        <Grid key={it.name} item xs={12}>{`${it.name}=${it.value}`}</Grid>))}
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card header="Image">
                            <Grid container>
                                <GridBold item xs={3}>Image:</GridBold>
                                <Grid item xs={9}>{element?.specification?.image?.name}</Grid>
                                <GridBold item xs={3}>Tag:</GridBold>
                                <Grid item xs={9}>{element?.specification?.image?.tag}</Grid>
                                <GridBold item xs={3}>Ports:</GridBold>
                                <Grid item xs={9}>{(element?.specification?.image?.ports || []).join(", ")}</Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card header="Endpoints">
                            <Grid container>
                                <GridBold item xs={3}>Health:</GridBold>
                                <Grid item
                                      xs={9}>{`${element?.specification?.endpoints?.health?.path} (port ${element?.specification?.endpoints?.health?.port})`}</Grid>
                                <GridBold item xs={3}>Ready:</GridBold>
                                <Grid item
                                      xs={9}>{`${element?.specification?.endpoints?.ready?.path} (port ${element?.specification?.endpoints?.ready?.port})`}</Grid>
                                <GridBold item xs={3}>Started:</GridBold>
                                <Grid item
                                      xs={9}>{`${element?.specification?.endpoints?.started?.path} (port ${element?.specification?.endpoints?.started?.port})`}</Grid>
                                <GridBold item xs={3}>OpenAPI:</GridBold>
                                <Grid item
                                      xs={9}>{`${element?.specification?.endpoints?.openApi?.path} (port ${element?.specification?.endpoints?.openApi?.port})`}</Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card header="Telemetry">
                            <Grid container>
                                <GridBold item xs={3}>Metrics:</GridBold>
                                <Grid item
                                      xs={9}>{`${element?.specification?.telemetry?.metricsEndpoint?.path} (port ${element?.specification?.telemetry?.metricsEndpoint?.port})`}</Grid>
                                <GridBold item xs={12}>Metrics to record:</GridBold>
                                {(element?.specification?.telemetry?.metricsToRecordAsRegex)?.map((it, i) => (
                                        <Grid key={i} item xs={12}>{it}</Grid>))}
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card header="Status">
                            <Grid container>
                                <GridBold item xs={3}>Status:</GridBold>
                                <Grid item xs={9}>
                                    {`${status.status}`}
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card header="Reachability">
                            <Grid container>
                                <GridBold item xs={3}>Is public:</GridBold>
                                <Grid item xs={9}>{`${element?.specification?.reachability?.isPublic || false}`}</Grid>
                                {element !== null ? (<>
                                    <GridBold item xs={3}>URL:</GridBold>
                                    <Grid item xs={9}><Link href={elementUrl || undefined}
                                                            target="_blank">{elementUrl}</Link></Grid>
                                </>) : null}
                                {element !== null && element.specification.endpoints.openApi.path ? (<>
                                    <GridBold item xs={3}>API:</GridBold>
                                    <Grid item xs={9}><Link target="_blank"
                                                            href={`${process.env.GATSBY_PATH_PREFIX}/deployments/${props.deploymentId}/api`}>
                                        Open API
                                    </Link></Grid>
                                </>) : null}
                                <GridBold item xs={3}>Domain:</GridBold>
                                <Grid item xs={9}>{`${element?.specification?.reachability?.domain || "-"}`}</Grid>
                                <GridBold item xs={3}>TLS:</GridBold>
                                <Grid item xs={9}>{`${element?.specification?.reachability?.domainUseTls}`}</Grid>
                                <GridBold item xs={3}>Security:</GridBold>
                                <Grid item xs={9}>
                                    {`Use basic auth: ${element?.specification?.reachability?.security?.useBasicAuth}`}
                                    <br/>
                                    {`Username: ${element?.specification?.reachability?.security?.basicAuthUsername || "-"}`}
                                    <br/>
                                    {`Password: ${element?.specification?.reachability?.security?.basicAuthUsername || "-"}`}
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Card header="Environment">
                            <Grid container>
                                <GridBold item xs={12}>Variables:</GridBold>
                                {(element?.specification?.environment?.variables)?.map(it => (
                                        <Grid key={it.name} item
                                              xs={12}>{`${it.name}=${it.encrypted ? "***" : it.value}`}</Grid>))}
                                <GridBold item xs={12}>Config files:</GridBold>
                                {(element?.specification?.environment?.configFiles)?.map(it => (
                                        <Grid key={it.name} item xs={12}>
                                            <Typography>{it.name}</Typography>
                                            <Typography>{atob(it.content)}</Typography>
                                        </Grid>))}
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>}
            </AuthenticatedLayout>
    )
}