import React, {SyntheticEvent, useContext, useState} from "react";
import {Box, BoxProps, Button, Grid, Paper, styled, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import IllustrationImage from "../../images/illustration.svg"
import {LoginResponse, setupLogin} from "../../misc/authentication";
import {isBrowser} from "../../misc/misc";
import {LockSharp} from "@mui/icons-material";
import {Helmet} from "react-helmet";
import Logo from "../../components/Logo";
import {navigate} from "gatsby";

import {UiConfigContext} from "../provider/UiConfigProvider";
import {LoginContext} from "../provider/LoginProvider";
import LoginForm from "../../components/form/LoginForm";
import {authAdapter} from "../../adapters/interfaces";
import {RouteComponentProps} from "@reach/router";

export interface Props extends RouteComponentProps {
}

export default function (props: Props) {
    const theme = useTheme()
    const {uiConfig} = useContext(UiConfigContext)
    const {setLogin} = useContext(LoginContext)
    const [showLocalLogin, setShowLocalLogin] = useState<boolean>(false)
    const [emailError, setEmailError] = useState<string | null>(null)
    const [passwordError, setPasswordError] = useState<string | null>(null)
    const [isLoading, setLoading] = useState<boolean>(false)

    const LoginSubTitle = styled(Box)<BoxProps>(() => ({
        fontSize: 16,
        fontFamily: 'Fira Code, monospace',
    }))

    const handleSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        if (!isBrowser) throw Error("Login is only possible inside a browser")
        setLoading(true)
        setEmailError(null)
        setPasswordError(null)
        const target = event.target as typeof event.target & {
            email: { value: string }
            password: { value: string }
        }
        const email = target.email.value.trim()
        const password = target.password.value
        if (email == '' || password == '') {
            if (email == '') setEmailError("Email not provided")
            if (password == '') setPasswordError("Password not provided")
            setLoading(false)
            return
        }

        authAdapter.login({email, password})
                .then((response: LoginResponse) => setupLogin(response.token, true, setLogin))
                .then(() => {
                    let redirect: string = new URL(location.href).searchParams.get('url') || '/app'
                    if (redirect.includes('/app/login')) {
                        redirect = '/app'
                    }
                    navigate(redirect)
                })
                .catch(error => {
                    console.error(error.message, error)
                    setEmailError(error.message)
                    setPasswordError(error.message)
                })
                .finally(() => setLoading(false))
    }

    return (
            <Grid container component="main" sx={{
                height: '100vh',
                backgroundImage: `url(${IllustrationImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundColor: theme.palette.background.default,
                backgroundSize: 'contain',
                backgroundPosition: 'bottom right'
            }}>
                <Helmet>
                    <title>Login - AIOS</title>
                </Helmet>
                <Grid item xs={12} sm={8} md={3} component={Paper}>
                    <div style={{
                        margin: theme.spacing(8, 4),
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Logo marginBottom={2}/>
                        <LoginSubTitle mb={0}>Artificial Intelligence</LoginSubTitle>
                        <LoginSubTitle mb={5}>Operating System</LoginSubTitle>
                        <Typography component="h1">
                            <LockSharp/>
                        </Typography>
                        {uiConfig?.keycloakEnabled ? (<>
                            <Button id="single-sign-on"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{margin: theme.spacing(3, 0, 2)}}
                                    onClick={() => {
                                        let redirect: string = new URL(location.href).searchParams.get('url') || '/'
                                        if (redirect.includes('/login')) {
                                            redirect = '/'
                                        }
                                        navigate(redirect)
                                    }}>Single sign on</Button>
                            <Button id="local-login-toggle"
                                    fullWidth
                                    color="primary"
                                    sx={{
                                        margin: theme.spacing(3, 0, 2),
                                        display: showLocalLogin ? 'none' : 'initial',
                                    }}
                                    onClick={() => setShowLocalLogin(true)}>Other login options</Button>
                        </>) : null}
                        <Box component="div"
                             sx={{display: showLocalLogin || !uiConfig?.keycloakEnabled ? 'initial' : 'none'}}>
                            <LoginForm id="login" emailError={emailError} passwordError={passwordError}
                                       isLoading={isLoading} onSubmit={handleSubmit}/>
                        </Box>
                    </div>
                </Grid>
                <Grid item xs={false} sm={4} md={9}/>
            </Grid>
    )
}
