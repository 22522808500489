import React, {useContext, useEffect, useState} from "react";
import AuthenticatedLayout from "../../../components/layout/AuthenticatedLayout";
import {LoginContext} from "../../provider/LoginProvider";
import {DeploymentResponse} from "../../../generated/models/DeploymentResponse";
import {deploymentAdapter, Page, pageable, userAdapter} from "../../../adapters/interfaces";
import {Alert, AlertTitle, IconButton, Tooltip} from "@mui/material";
import {NumberParam, StringParam, useQueryParam} from "use-query-params";
import {CsvArrayParam} from "../../../misc/CsvArrayParam";
import {DataGrid, GridFilterModel, GridRowId, GridSortModel} from "@mui/x-data-grid";
import {FlatPaper} from "../../Misc";
import {columns} from "./DeploymentModel";
import {assembleFilter, assembleSort} from "../../../misc/misc";
import {CompareArrowsSharp} from "@mui/icons-material";
import DeploymentDiffDialog from "./DeploymentDiffDialog";
import {useHotkeys} from "react-hotkeys-hook";
import {RouteComponentProps} from "@reach/router";

export interface Props extends RouteComponentProps {
    deploymentId?: string
    sort?: string
}

export default function (props: Props) {
    const SORT_DEFAULT: string = props.sort ? encodeURIComponent(props.sort) : encodeURIComponent("-createdAt")

    const {login} = useContext(LoginContext)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [pageNumber = 0, setPageNumber] = useQueryParam("page", NumberParam)
    const [pageSize = 10, setPageSize] = useQueryParam("size", NumberParam)
    const [sort = SORT_DEFAULT, setSort] = useQueryParam("sort", new CsvArrayParam())
    const [filter, setFilter] = useQueryParam("filter", StringParam)
    const [compareDialogOpen, setCompareDialogOpen] = useState<boolean>(false)
    const [element, setElement] = useState<DeploymentResponse | null>(null)
    const [elementsPage, setElementsPage] = useState<Page<DeploymentResponse> | null>(null)
    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const [filterModel, setFilterModel] = useState<GridFilterModel | null>(null)
    const [selectionModel, setSelectionModel] = useState<GridRowId[]>([])
    const [selectedElement, setSelectedElement] = useState<DeploymentResponse | null>(null)
    const [selectedElements, setSelectedElements] = useState<DeploymentResponse[]>([])

    const load = (): void => {
        if (!props.deploymentId) return
        setLoading(true)
        deploymentAdapter.findVersions(login, props.deploymentId, pageable(pageNumber, pageSize, filter || null, sort ? decodeURIComponent(sort.toString()) : null))
                .then(response => setElementsPage(response))
                .catch(error => setError(error.message))
                .finally(() => setLoading(false))
    }

    useHotkeys('Shift + c', () => {
        document.getElementById('compare')!.click()
    })
    useEffect(() => { // load elements depending on the page params
        console.debug("Loading element versions")
        load()
    }, [pageNumber, pageSize, filter, sort])
    useEffect(() => {
        if (!props.deploymentId) return
        console.debug("Loading latest element")
        deploymentAdapter.get(login, props.deploymentId)
                .then(response => setElement(response))
                .catch(error => setError(error.message))
    }, [])
    useEffect(() => { // update state of selected element
        const element = selectionModel.length == 1 ? elementsPage?.elements.find(it => it.version === selectionModel[0]) || null : null
        console.debug("Setting selected element and id", element)
        setSelectedElement(element)
        const elements = elementsPage?.elements.filter(it => selectionModel.includes(it.version)) || []
        console.debug("Setting selected elements", elements)
        setSelectedElements(elements)
    }, [selectionModel, elementsPage])

    const buttonBar = (<>
                <Tooltip title="Compare (shift + c)" placement="bottom">
                    <span><IconButton id="compare" disabled={error != null || selectionModel.length != 2}
                                      onClick={() => setCompareDialogOpen(true)}><CompareArrowsSharp/></IconButton></span>
                </Tooltip>
                <DeploymentDiffDialog id="compare-dialog"
                                      title={`Deployment diff - ${element?.name} Version ${selectedElements.length == 2 ? selectedElements[1].version : ""} : Version ${selectedElements.length == 2 ? selectedElements[0].version : ""}`}
                                      open={compareDialogOpen}
                                      onClose={() => setCompareDialogOpen(false)}
                                      original={selectedElements.length == 2 ? selectedElements[1] : null}
                                      modified={selectedElements.length == 2 ? selectedElements[0] : null}
                />
            </>
    )

    return (
            <AuthenticatedLayout title={`Deployment ${element?.name}`}
                                 topRightSection={buttonBar}
                                 breadcrumbs={[{name: "Overview", link: "/app"}, {
                                     name: "Deployments",
                                     link: "/deployments",
                                 }, {
                                     name: element?.name || "",
                                     link: `/deployments/${props.deploymentId}`,
                                 }, {
                                     name: "Versions",
                                     link: `/deployments/${props.deploymentId}/versions`,
                                 }]}>
                {error != null ? (
                        <FlatPaper>
                            <Alert severity="error">
                                <AlertTitle>Failed to load versions</AlertTitle>
                                {error}
                            </Alert>
                        </FlatPaper>
                ) : (
                        <FlatPaper id="content" style={{height: '100%', minHeight: '650px'}}>
                            <DataGrid rows={elementsPage?.elements || []}
                                      columns={columns(login, userAdapter).slice(1, -1)}
                                      getRowId={row => row.version}
                                      checkboxSelection disableSelectionOnClick
                                      className="elements" getRowClassName={(() => "element")}
                                      pagination paginationMode="server" pageSize={pageSize || 0}
                                      rowsPerPageOptions={[10, 25, 50, 100]}
                                      rowCount={elementsPage?.totalElements || 0}
                                      onPageChange={(page => setPageNumber(page))}
                                      onPageSizeChange={(pageSize => setPageSize(pageSize))}
                                      filterMode="server" filterModel={filterModel || undefined}
                                      onFilterModelChange={(model => {
                                          setFilterModel(model)
                                          setFilter(assembleFilter(model))
                                      })}
                                      sortingMode="server" sortModel={sortModel}
                                      onSortModelChange={(model => {
                                          setSortModel(model)
                                          setSort(assembleSort(model))
                                      })}
                                      selectionModel={selectionModel}
                                      onSelectionModelChange={model => setSelectionModel(model)}
                                      loading={isLoading}/>
                        </FlatPaper>
                )}
            </AuthenticatedLayout>
    )
}