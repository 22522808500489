import React, {SyntheticEvent, useState} from "react";
import FormDialog from "../../dialog/FormDialog";
import {LoginHolder} from "../../provider/LoginProvider";
import {DeploymentResponse} from "../../../generated/models/DeploymentResponse";
import {DeploymentAdapter} from "../../../adapters/DeploymentAdapter";
import {TenantResponse} from "../../../generated/models/TenantResponse";
import {CreateDeploymentRequest} from "../../../generated/models/CreateDeploymentRequest";
import CodeEditor, {CodeEditorMarker, CodeEditorMarkerSeverity} from "../../CodeEditor";
import {deploymentSchema, deploymentSpecificationSchema} from "./DeploymentModel";
import {Grid} from "@mui/material";

export interface Props {
    id: string
    title: string
    open: boolean
    onClose?: () => void
    onSubmitted?: () => void
    adapter: DeploymentAdapter
    login: LoginHolder | null
    tenant: TenantResponse | null
    deployment: DeploymentResponse
}

export default function (props: Props) {
    const {
        id,
        version,
        tenantId,
        userId,
        createdAt,
        ...editDeploymentRequest
    } = props.deployment

    const onClose: () => void = () => {
        setLoading(false)
        setError(null)
        setEditorMarkers([])
        if (props.onClose) props.onClose()
    }

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [editorMarkers, setEditorMarkers] = useState<CodeEditorMarker[]>([])

    const onSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setError(null)

        const errorMarkers = editorMarkers.filter(m => m.severity === CodeEditorMarkerSeverity.Error)
        if (errorMarkers.length > 0) {
            if (errorMarkers.length === 1) {
                setError(`Please fix the error at line ${errorMarkers[0].endLineNumber}.`)
            } else {
                setError(`Please fix all ${errorMarkers.length} errors at lines ${errorMarkers.map(m => m.endLineNumber).join(", ")}.`)
            }
            setLoading(false)
            return
        }

        const data = new FormData(event.currentTarget as HTMLFormElement)
        const rawDeployment = JSON.parse(data.get("deployment") as string) as any
        if (JSON.stringify(editDeploymentRequest) === JSON.stringify(rawDeployment)) {
            setError(`Nothing changed.`)
            setLoading(false)
            return
        }
        const deployment: CreateDeploymentRequest = Object.assign({tenantId: props.tenant!.id}, rawDeployment)
        props.adapter.updateOne(props.login, {id, ...deployment})
                .then(() => {
                    if (props.onSubmitted) props.onSubmitted()
                })
                .catch(error => setError(error.message))
                .finally(() => setLoading(false))
    }

    return (<FormDialog id={props.id} title={props.title} submitButton="Edit"
                        errorTitle="Failed to edit element." error={error} open={props.open}
                        isLoading={isLoading}
                        onClose={onClose} onSubmit={onSubmit}>
        <Grid item xs={12}>
            <CodeEditor id={`${props.id}-editor`}
                        name="deployment"
                        formId={`${props.id}-form`}
                        width="560px" height="70vh"
                        language="json"
                        schemas={[deploymentSchema, deploymentSpecificationSchema]}
                        value={JSON.stringify(editDeploymentRequest, null, 2)}
                        onChangeMarkers={m => setEditorMarkers(m)}/>
        </Grid>
    </FormDialog>)
}
