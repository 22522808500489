import React, {useContext, useEffect, useState} from "react";
import {LoginContext} from "../../provider/LoginProvider";
import {DeploymentResponse} from "../../../generated/models/DeploymentResponse";
import {deploymentAdapter} from "../../../adapters/interfaces";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import {TenantContext} from "../../provider/TenantProvider";
import {buildDeploymentUrl} from "./DeploymentModel";
import {UiConfigContext} from "../../provider/UiConfigProvider";
import {Helmet} from "react-helmet";
import {RouteComponentProps} from "@reach/router";

export interface Props extends RouteComponentProps {
    deploymentId?: string
}

export default function (props: Props) {
    const {uiConfig} = useContext(UiConfigContext)
    const {login} = useContext(LoginContext)
    const {tenant} = useContext(TenantContext)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [element, setElement] = useState<DeploymentResponse | null>(null)
    const [openApiUrl, setOpenApiUrl] = useState<string | null>(null)

    const load = (): void => {
        if (!props.deploymentId) return
        setLoading(true)
        deploymentAdapter.get(login, props.deploymentId)
                .then(response => {
                    setElement(response)
                    if (!response?.enabled) {
                        setError("Deployment is not enabled. API documentation can only be shown if deployment is enabled.")
                    }
                })
                .catch(error => setError(error.message))
                .finally(() => {
                    setLoading(false)
                })
    }

    useEffect(() => {
        console.debug("Loading element")
        load()
    }, [])
    useEffect(() => {
        if (element && tenant && uiConfig) {
            if (element.specification.endpoints.openApi) {
                setOpenApiUrl(`${buildDeploymentUrl(element, tenant.tenant, uiConfig.rootUrl)}${element.specification.endpoints.openApi.path}`)
            } else {
                setError("No OpenAPI url found in the deployment specification.")
            }
        }
    }, [element, tenant, uiConfig])

    return (<div>
        <Helmet><title>HTTP API - {element?.name}</title></Helmet>
        {error ? error : (<SwaggerUI url={openApiUrl || undefined} persistAuthorization={true}/>)}
    </div>)
}