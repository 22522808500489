import React, {useContext, useEffect, useState} from "react";
import {
    Alert,
    AlertTitle,
    Avatar,
    Badge,
    Box,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography
} from "@mui/material";
import AuthenticatedLayout from "../../components/layout/AuthenticatedLayout";
import {displayName, initials} from "../../misc/misc";
import {AttachmentSharp} from "@mui/icons-material";
import {FlatPaper} from "../Misc";
import {LoginContext} from "../provider/LoginProvider";
import {UserSettingDefinition} from "../../adapters/UserSettingAdapter";
import {UserSettingResponse} from "../../generated/models/UserSettingResponse";
import {TenantResponse} from "../../generated/models/TenantResponse";
import {Page, PageableRequest, tenantAdapter, userSettingAdapter} from "../../adapters/interfaces";
import {RouteComponentProps} from "@reach/router";

export interface Props extends RouteComponentProps {
    title: string
}

export default function (props: Props) {
    const {login} = useContext(LoginContext)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [error] = useState<string | null>(null)
    const [tenantsPage, setTenantsPage] = useState<Page<TenantResponse> | null>(null)
    const [settingsPage, setSettingsPage] = useState<Page<UserSettingResponse> | null>(null)
    const [settingDefaultTenant, setSettingDefaultTenant] = useState<string | null>(null) // the tenant id

    const loadSettings = (): Promise<void> => {
        return userSettingAdapter.find(login, new PageableRequest(0, 1000, null, "+name"))
                .then((response) => {
                    setSettingsPage(response)
                })
    }

    const updateSetting = (name: UserSettingDefinition, value: string | null) => {
        const defName: string = UserSettingDefinition[name]
        if (value == null) {
            userSettingAdapter.deleteOne(login, defName).then(() => loadSettings())
        } else if (settingsPage?.elements.find(s => s.name === defName)) {
            userSettingAdapter.updateOne(login, {name: defName, value}).then(() => loadSettings())
        } else {
            userSettingAdapter.createOne(login, {name: defName, value}).then(() => loadSettings())
        }
    }

    useEffect(() => {
        // loading is for not loading tenants multiple times
        if (!isLoading && tenantsPage == null && settingsPage == null) {
            setLoading(true)
            tenantAdapter.find(login, new PageableRequest(0, 25, null, "+alias")).then(response => {
                setTenantsPage(response)
                return loadSettings()
            }).finally(() => setLoading(false))
        }
    })
    useEffect(() => { // update default tenant setting
        setSettingDefaultTenant(settingsPage?.elements.find(s => s.name === UserSettingDefinition[UserSettingDefinition.DEFAULT_TENANT])?.value || null)
    }, [settingsPage])

    return (<AuthenticatedLayout title={props.title}
                                 breadcrumbs={[{name: "Overview", link: "/app"}, {
                                     name: props.title,
                                     link: props.uri || ""
                                 }]}>
        {error != null ? (
                <FlatPaper>
                    <Alert severity="error">
                        <AlertTitle>Failed to load elements</AlertTitle>
                        {error}
                    </Alert>
                </FlatPaper>
        ) : (
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={8} id="user-area">
                        <FlatPaper variant="outlined">
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={2}>
                                    <Box m={2}>
                                        <Avatar sx={{
                                            width: '100px',
                                            height: '100px',
                                            fontSize: '2em',
                                            fontWeight: 'bold',
                                        }}>{initials(login)}</Avatar>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <Box m={2}>
                                        <Typography gutterBottom variant="h4" component="h2">
                                            {displayName(login)}
                                        </Typography>
                                        <Typography gutterBottom variant="h5" component="h3">
                                            {login?.email}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </FlatPaper>
                    </Grid>
                    <Grid item xs={12} sm={4} id="tenant-area">
                        <FlatPaper variant="outlined">
                            <Box m={2}>
                                <Typography variant="h6" component="h3">Tenants</Typography>
                                <List>
                                    {tenantsPage && tenantsPage.elements.map(t => (
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <div>
                                                        <Box component="span"
                                                             display={settingDefaultTenant === t.id ? 'block' : 'none'}>
                                                            <Badge overlap="circular" color="secondary" variant="dot"
                                                                   anchorOrigin={{
                                                                       horizontal: 'right',
                                                                       vertical: 'bottom',
                                                                   }}>
                                                                <Avatar>{t.name.charAt(0)}</Avatar>
                                                            </Badge>
                                                        </Box>
                                                        <Box component="span"
                                                             display={settingDefaultTenant !== t.id ? 'block' : 'none'}>
                                                            <Avatar>{t.name.charAt(0)}</Avatar>
                                                        </Box>
                                                    </div>
                                                </ListItemAvatar>
                                                <ListItemText primary={t.name} secondary={t.alias}/>
                                                <Box component="span"
                                                     display={settingDefaultTenant !== t.id ? 'block' : 'none'}>
                                                    <ListItemSecondaryAction>
                                                        <Tooltip title="Set as default" placement="left">
                                                            <IconButton edge="end"
                                                                        onClick={() => updateSetting(UserSettingDefinition.DEFAULT_TENANT, t.id)}>
                                                                <AttachmentSharp/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </ListItemSecondaryAction>
                                                </Box>
                                            </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </FlatPaper>
                    </Grid>
                </Grid>
        )}
    </AuthenticatedLayout>)
}
