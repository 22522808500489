import React, {SyntheticEvent, useState} from "react";
import FormDialog from "../../dialog/FormDialog";
import {TenantResponse} from "../../../generated/models/TenantResponse";
import {UserRoleRequest} from "../../../generated/models/UserRoleRequest";
import {RoleResponse} from "../../../generated/models/RoleResponse";
import {LoginHolder} from "../../provider/LoginProvider";
import {UserResponse} from "../../../generated/models/UserResponse";
import UserInviteForm from "./UserInviteForm";
import {UserAdapter} from "../../../adapters/UserAdapter";

export interface Props {
    id: string
    title: string
    open: boolean
    tenants: TenantResponse[]
    roleDefsPerTenant: Record<string, RoleResponse[]>
    onClose?: () => void
    onSubmitted?: (response: UserResponse) => void
    onTenantsChange?: (tenants: TenantResponse[]) => void
    adapter: UserAdapter
    login: LoginHolder | null
}

export default function (props: Props) {

    const onClose: () => void = () => {
        setLoading(false)
        setError(null)
        setEmailError(null)
        setRolesError(null)
        if (props.onClose) props.onClose()
    }

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [emailError, setEmailError] = useState<string | null>(null)
    const [tenantsError, setTenantsError] = useState<string | null>(null)
    const [rolesError, setRolesError] = useState<string | null>(null)

    const onSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setError(null)
        setEmailError(null)
        setTenantsError(null)
        setRolesError(null)

        const data = new FormData(event.currentTarget as HTMLFormElement)
        const email = (data.get("email") as string).trim()
        const tenantIds: string[] = (data.get("tenants") as string).split(",").filter((it: string) => !!it)
        if (tenantIds.length == 0) {
            setError("Inviting user failed")
            setTenantsError("At least one tenant must be selected")
            setLoading(false)
            return
        }
        let roles: UserRoleRequest[] = []
        tenantIds.forEach(tenantId => {
            const tenantRoles = data.get(`roles-${tenantId}`) as string | null
            if (!tenantRoles) return
            const tenantRoleIds: string[] = tenantRoles.split(",").filter((it: string) => !!it).map((it: string) => it)
            roles = roles.concat(tenantRoleIds.map(roleId => ({tenantId, roleId})))
        })
        props.adapter.invite(props.login, {email, tenantIds, roles})
                .then((response: UserResponse) => {
                    if (props.onSubmitted) props.onSubmitted(response)
                })
                .catch(error => setError(error.message))
                .finally(() => setLoading(false))
    }

    return (<FormDialog id={props.id} title={props.title} submitButton="Invite"
                        errorTitle="Failed to invite element." error={error} open={props.open}
                        isLoading={isLoading}
                        onClose={onClose} onSubmit={onSubmit}>
        <UserInviteForm id={`${props.id}-form`}
                        tenants={props.tenants}
                        roleDefsPerTenant={props.roleDefsPerTenant || {}}
                        onTenantsChange={tenants => {
                            if (props.onTenantsChange) props.onTenantsChange(tenants)
                        }}
                        emailError={emailError}
                        tenantsError={tenantsError}
                        rolesError={rolesError}
        />
    </FormDialog>)
}
