import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {TenantResponse} from "../../../generated/models/TenantResponse";
import {RoleResponse} from "../../../generated/models/RoleResponse";
import EmailInput from "../../form/EmailInput";
import TextInput from "../../form/TextInput";
import TenantInput from "../../form/TenantInput";
import RoleInput from "../../form/RoleInput";
import PasswordInput from "../../form/PasswordInput";

export interface Props {
    id: string
    tenants: TenantResponse[]
    roleDefsPerTenant: Record<string, RoleResponse[]>
    passwordRegex: string
    passwordHelp: string
    onTenantsChange: (tenants: TenantResponse[]) => void

    emailError: string | null
    passwordError: string | null
    firstnameError: string | null
    lastnameError: string | null
    tenantsError: string | null
    rolesError: string | null
}

export default function (props: Props) {
    const [selectedTenants, setSelectedTenants] = useState<TenantResponse[]>([])

    const [emailError, setEmailError] = useState<string | null>(props.emailError)
    const [passwordError, setPasswordError] = useState<string | null>(props.passwordError)
    const [firstnameError, setFirstnameError] = useState<string | null>(props.firstnameError)
    const [lastnameError, setLastnameError] = useState<string | null>(props.lastnameError)

    useEffect(() => props.onTenantsChange(selectedTenants), [selectedTenants])
    useEffect(() => setEmailError(props.emailError), [props.emailError])
    useEffect(() => setPasswordError(props.passwordError), [props.passwordError])
    useEffect(() => setFirstnameError(props.firstnameError), [props.firstnameError])
    useEffect(() => setLastnameError(props.lastnameError), [props.lastnameError])

    return (<>
        <Grid item xs={12}>
            <EmailInput id={`${props.id}-email`}
                        error={emailError}
                        autoFocus={true}
                        helpMessage="The email of the user to be used for the login."/>
        </Grid>
        <Grid item xs={12}>
            <PasswordInput id={`${props.id}-password`}
                           error={passwordError}
                           pattern={props.passwordRegex}
                           helpMessage={`The password for the user (${props.passwordHelp}).`}/>
        </Grid>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-firstname`} label="Firstname" name="firstname" error={firstnameError}
                       required={false} helpMessage="The firstname of the user."/>
        </Grid>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-lastname`} label="Lastname" name="lastname" error={lastnameError}
                       required={false} helpMessage="The lastname of the user."/>
        </Grid>
        <Grid item xs={12}>
            <TenantInput id={`${props.id}-tenants`}
                         options={props.tenants || []}
                         onChange={values => setSelectedTenants(values)}
                         error={props.tenantsError}
            />
        </Grid>
        {selectedTenants.map(tenant => (
                <Grid key={tenant.id} item xs={12}>
                    <RoleInput id={`${props.id}-roles-${tenant.id}`}
                               name={`roles-${tenant.id}`}
                               options={props.roleDefsPerTenant?.[tenant.id] || []}
                               error={props.rolesError}
                               helpMessage={`Select roles for tenant ${tenant.name}.`}
                    />
                </Grid>
        ))}
    </>)
}
