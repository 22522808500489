import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import TextInput from "../../form/TextInput";
import {timestamp} from "../../../misc/timestamp";
import {ApiKeyResponse} from "../../../generated/models/ApiKeyResponse";
import RoleInput from "../../form/RoleInput";
import {RoleResponse} from "../../../generated/models/RoleResponse";

export interface Props {
    id: string
    roles: RoleResponse[]
    element: ApiKeyResponse | null

    descriptionError: string | null
}

export default function (props: Props) {
    const [descriptionError, setDescriptionError] = useState<string | null>(props.descriptionError)

    useEffect(() => setDescriptionError(props.descriptionError), [props.descriptionError])

    return (<>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-id`} label="ID" name="id"
                       value={props.element?.id}
                       required={true}
                       readonly={true}
                       maxLength={36}
            />
        </Grid>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-description`} label="Description" name="description"
                       value={props.element?.description}
                       error={descriptionError}
                       autoFocus={true}
                       multiline={true}
                       helpMessage="The description of the API key."
            />
        </Grid>
        <Grid item xs={12}>
            <RoleInput id={`${props.id}-roles`}
                       name="roles"
                       options={props.roles}
                       value={props.element?.roles.map(akr => props.roles.find(r => r.id === akr.roleId)!)}
                       readonly={true}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput id={`${props.id}-createdAt`} label="Created" name="createdAt"
                       value={timestamp(props.element?.createdAt || null).format()} required={true} readonly={true}
                       maxLength={0}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput id={`${props.id}-updatedAt`} label="Updated" name="updatedAt"
                       value={timestamp(props.element?.updatedAt || null).format()} required={true} readonly={true}
                       maxLength={0}
            />
        </Grid>
    </>)
}
