import moment, {Moment} from "moment";

export interface Timestamp {
    /**
     * @return ISO formatted timestamp in the local time zone.
     */
    format(): string

    /**
     * @return The approximate, relative timestamp in the local time zone. Easier for humans.
     */
    formatFromNow(): string
}

export const timestamp: (timestamp: string | number | Date | null) => Timestamp = (timestamp: string | number | Date | null = new Date()) => {
    return new TimestampImpl(timestamp)
}

class TimestampImpl implements Timestamp {
    private readonly timestamp: Moment | null

    constructor(timestamp: string | number | Date | null) {
        this.timestamp = moment(timestamp)
    }

    format(): string {
        if (this.timestamp == null) return "";
        return this.timestamp.local().format();
    }

    formatFromNow(): string {
        if (this.timestamp == null) return "";
        return this.timestamp.local().fromNow();
    }

}
