import React, {useEffect, useRef, useState} from "react";
import {loader} from "@monaco-editor/react";
import {Box, CircularProgress} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {darkTheme, editorOptions, jsonSchemaDiagnosticOptions, lightTheme} from "./CodeEditor";

export interface Props {
    id: string
    name?: string
    width: string
    height: string
    language: "json"
    labelOriginal: string
    valueOriginal: string
    labelModified: string
    valueModified: string
    schemas?: any[]
    onReady?: () => void
}

export default function (props: Props) {
    const theme = useTheme()
    const editorRef = useRef<any | null>(null)
    const [isLoading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        setLoading(true)
        let modelOriginal: any | null = null
        let modelModified: any | null = null

        loader.init().then(monaco => {
            const modelOriginalUri = monaco.Uri.parse(`aios://${props.id}/original/${new Date()}`); // a made up unique URI
            const modelModifiedUri = monaco.Uri.parse(`aios://${props.id}/modified/${new Date()}`); // a made up unique URI
            const wrapper = document.getElementById(props.id)
            if (!wrapper) return Promise.reject(`Wrapper with id ${props.id} not found`)
            modelOriginal = monaco.editor.createModel(props.valueOriginal, props.language, modelOriginalUri)
            modelModified = monaco.editor.createModel(props.valueModified, props.language, modelModifiedUri)
            if (props.language === "json" && props.schemas) {
                monaco.languages.json.jsonDefaults.setDiagnosticsOptions(jsonSchemaDiagnosticOptions(props.schemas))
            }
            monaco.editor.defineTheme("AIOS", theme.palette.mode === "dark" ? darkTheme(theme) : lightTheme(theme))
            editorRef.current = monaco.editor.createDiffEditor(wrapper, {
                readOnly: true,
                originalAriaLabel: props.labelOriginal,
                modifiedAriaLabel: props.labelModified,
                ...editorOptions,
            })
            editorRef.current.setModel({original: modelOriginal, modified: modelModified})
            if (props.onReady) props.onReady()
            setLoading(false)
        }).catch(error => console.error("Error initializing diff editor", error))
        return () => {
            // cleanup models
            if (modelOriginal) {
                console.debug("Disposing original model")
                modelOriginal.dispose()
            }
            if (modelModified) {
                console.debug("Disposing modified model")
                modelModified.dispose()
            }
        }
    }, [])

    return (<>
        {isLoading ? (<Box component="span" display="flex" justifyContent="center"
                           alignItems="center"><CircularProgress/></Box>) : null}
        <Box id={props.id}
             component="div"
             sx={{width: props.width, height: props.height}}/>
    </>)
}
