import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {TenantResponse} from "../../../generated/models/TenantResponse";
import {RoleResponse} from "../../../generated/models/RoleResponse";
import EmailInput from "../../form/EmailInput";
import TenantInput from "../../form/TenantInput";
import RoleInput from "../../form/RoleInput";

export interface Props {
    id: string
    tenants: TenantResponse[]
    roleDefsPerTenant: Record<string, RoleResponse[]>
    onTenantsChange: (tenants: TenantResponse[]) => void

    emailError: string | null
    tenantsError: string | null
    rolesError: string | null
}

export default function (props: Props) {
    const [selectedTenants, setSelectedTenants] = useState<TenantResponse[]>([])

    const [emailError, setEmailError] = useState<string | null>(props.emailError)

    useEffect(() => props.onTenantsChange(selectedTenants), [selectedTenants])
    useEffect(() => setEmailError(props.emailError), [props.emailError])

    return (<>
        <Grid item xs={12}>
            <EmailInput id={`${props.id}-email`}
                        error={emailError}
                        autoFocus={true}
                        helpMessage="The email of the user to be used for the login."/>
        </Grid>
        <Grid item xs={12}>
            <TenantInput id={`${props.id}-tenants`}
                         options={props.tenants || []}
                         onChange={values => setSelectedTenants(values)}
                         error={props.tenantsError}
            />
        </Grid>
        {selectedTenants.map(tenant => (
                <Grid key={tenant.id} item xs={12}>
                    <RoleInput id={`${props.id}-roles-${tenant.id}`}
                               name={`roles-${tenant.id}`}
                               options={props.roleDefsPerTenant?.[tenant.id] || []}
                               error={props.rolesError}
                               helpMessage={`Select roles for tenant ${tenant.name}.`}
                    />
                </Grid>
        ))}
    </>)
}
