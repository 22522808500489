import {Autocomplete, Checkbox, TextField} from "@mui/material";
import {CheckBoxOutlineBlankSharp, CheckBoxSharp} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {TenantResponse} from "../../generated/models/TenantResponse";

export interface Props {
    id: string
    label?: string
    name?: string
    options: TenantResponse[]
    value?: TenantResponse[]
    onChange?: (tenants: TenantResponse[]) => void
    error?: string | null
    helpMessage?: string
    invalidMessage?: string
    multiple?: boolean
    readonly?: boolean
    disabled?: boolean
}

export default function (props: Props) {
    const DEFAULT_LABEL: string = "Tenants"
    const DEFAULT_NAME: string = "tenants"
    const DEFAULT_HELP_MESSAGE: string = "Select a tenant to connect a user with."

    const name: string = props.name || DEFAULT_NAME
    const multiple: boolean = props.multiple === undefined ? true : props.multiple

    const [value, setValue] = useState<TenantResponse[]>(props.value === undefined ? [] : props.value)
    const [error, setError] = useState<string | null>(props.error === undefined ? null : props.error)

    useEffect(() => setValue(props.value === undefined ? [] : props.value), [props.value])
    useEffect(() => setError(props.error === undefined ? null : props.error), [props.error])

    return (<>
        <input id={`${props.id}-form-${name}`} type="hidden" name={name} defaultValue={value.map(e => e.id).join()}/>
        <Autocomplete id={`${props.id}-form-${name}-input`} multiple={multiple} fullWidth disableCloseOnSelect
                      options={props.options || []} size="small"
                      getOptionLabel={(option: TenantResponse | undefined) => option?.name || "?"}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, values) => {
                          let tenants: TenantResponse[] = []
                          if (Array.isArray(values)) {
                              tenants = values
                          } else if (values) {
                              tenants = [values]
                          }
                          setValue(tenants)
                          console.debug(`Set value of tenants to`, value)
                          if (props.onChange) props.onChange(tenants)
                      }}
                      value={value}
                      renderOption={(renderProps, option, {selected}) => (
                              <li {...renderProps}>
                                  <Checkbox id={`${props.id}-option-${option.alias}`}
                                            name={`${name}-${option.id}`} checked={selected}
                                            icon={<CheckBoxOutlineBlankSharp fontSize="small"/>}
                                            checkedIcon={<CheckBoxSharp fontSize="small"/>}
                                            style={{marginRight: 8}}/>
                                  {option.name}
                              </li>
                      )}
                      renderInput={(params) => (
                              <TextField {...params}
                                         variant="outlined"
                                         error={props.error != null}
                                         label={props.label || DEFAULT_LABEL}
                                         helperText={error || props.helpMessage || DEFAULT_HELP_MESSAGE}/>
                      )}/>
    </>)
}
