import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {TenantResponse} from "../../../generated/models/TenantResponse";
import TextInput from "../../form/TextInput";
import {timestamp} from "../../../misc/timestamp";
import {ALIAS_REGEX} from "./TenantModel";

export interface Props {
    id: string
    element: TenantResponse | null

    nameError: string | null
}

export default function (props: Props) {
    const [nameError, setNameError] = useState<string | null>(props.nameError)

    useEffect(() => setNameError(props.nameError), [props.nameError])

    return (<>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-id`} label="ID" name="id"
                       value={props.element?.id}
                       required={true}
                       readonly={true}
                       maxLength={36}
            />
        </Grid>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-name`} label="Name" name="name"
                       value={props.element?.name}
                       error={nameError}
                       autoFocus={true}
                       helpMessage="The name of the tenant."
            />
        </Grid>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-alias`} label="Alias" name="alias"
                       value={props.element?.alias}
                       required={true}
                       readonly={true}
                       maxLength={16}
                       pattern={ALIAS_REGEX}
                       helpMessage="The alias for the tenant. Must be a valid DNS name and cannot be changed later."
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput id={`${props.id}-createdAt`} label="Created" name="createdAt"
                       value={timestamp(props.element?.createdAt || null).format()} required={true} readonly={true}
                       maxLength={0}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput id={`${props.id}-updatedAt`} label="Updated" name="updatedAt"
                       value={timestamp(props.element?.updatedAt || null).format()} required={true} readonly={true}
                       maxLength={0}
            />
        </Grid>
    </>)
}
