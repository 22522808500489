import DeleteDialog from "../../dialog/DeleteDialog";
import React, {useState} from "react";
import {UUID} from "../../../adapters/interfaces";
import {LoginHolder} from "../../provider/LoginProvider";
import {ApiKeyAdapter} from "../../../adapters/ApiKeyAdapter";

export interface Props {
    id: string
    title: string
    open: boolean
    idsToDelete: UUID[]
    onClose?: () => void
    onSubmitted?: () => void
    adapter: ApiKeyAdapter
    login: LoginHolder | null
}

export default function (props: Props) {

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    const onClose: () => void = () => {
        setLoading(false)
        setError(null)
        if (props.onClose) props.onClose()
    }

    const onSubmit = (): void => {
        setLoading(true)
        setError(null)
        props.adapter.delete(props.login, props.idsToDelete)
                .then(() => {
                    if (props.onSubmitted) props.onSubmitted()
                })
                .catch(error => setError(error.message))
                .finally(() => setLoading(false))
    }

    return (<DeleteDialog title={props.title} errorTitle="Deletion failed."
                          error={error} open={props.open} isLoading={isLoading}
                          onClose={onClose}
                          onSubmit={onSubmit}>
        {props.idsToDelete.length > 1 ? (
                <span>Do you really want to delete <strong>{props.idsToDelete.length} elements</strong>?</span>
        ) : (
                <span>Do you really want to delete element with ID <strong>{props.idsToDelete[0]}</strong>?</span>
        )}
    </DeleteDialog>)
}