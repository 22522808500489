import React from "react";
import {Router} from "@reach/router"
import NotFoundView from "../components/view/NotFoundView";
import OverviewView from "../components/view/OverviewView";
import LoginView from "../components/view/LoginView";
import UserView from "../components/view/user/UsersView";
import TenantsView from "../components/view/tenant/TenantsView";
import SettingsView from "../components/view/SettingsView";
import ApiDocsView from "../components/view/ApiDocsView";
import TenantSettingsView from "../components/view/TenantSettingsView";
import AccountView from "../components/view/AccountView";
import ApiKeysView from "../components/view/apikey/ApiKeysView";
import PasswordResetView from "../components/view/PasswordResetView";
import PasswordForgottenView from "../components/view/PasswordForgottenView";
import DeploymentsView from "../components/view/deployment/DeploymentsView";
import DeploymentView from "../components/view/deployment/DeploymentView";
import DeploymentVersionsView from "../components/view/deployment/DeploymentVersionsView";
import DeploymentApiView from "../components/view/deployment/DeploymentApiView";

export default function App() {
    return (
        <Router basepath="/app">
            <NotFoundView default/>
            <OverviewView path="/" title="Overview"/>
            <LoginView path="/login"/>
            <PasswordResetView path="/password-reset" title="Password reset"/>
            <PasswordForgottenView path="/password-forgotten" title="Password forgotten"/>
            <AccountView path="/account" title="Account"/>
            <ApiKeysView path="/account/api-keys" title="API keys" topic="API key"/>
            <UserView path="/users" title="Users" topic="user"/>
            <TenantsView path="/tenants" title="Tenants" topic="tenant"/>
            <TenantSettingsView path="/tenant/settings" title="Tenant settings"/>
            <SettingsView path="/settings" title="Settings"/>
            <ApiDocsView path="/api" title="HTTP API"/>
            <DeploymentsView path="/deployments" title="Deployments" topic="deployment"/>
            <DeploymentView path="/deployments/:deploymentId"/>
            <DeploymentVersionsView path="/deployments/:deploymentId/versions"/>
            <DeploymentApiView path="/deployments/:deploymentId/api"/>
        </Router>
    )
}
