import React, {SyntheticEvent, useState} from "react";
import FormDialog from "../../dialog/FormDialog";
import {TenantResponse} from "../../../generated/models/TenantResponse";
import {LoginHolder} from "../../provider/LoginProvider";
import {TenantAdapter} from "../../../adapters/TenantAdapter";
import TenantEditForm from "./TenantEditForm";

export interface Props {
    id: string
    title: string
    open: boolean
    element: TenantResponse | null
    onClose?: () => void
    onSubmitted?: () => void
    adapter: TenantAdapter
    login: LoginHolder | null
}

export default function (props: Props) {

    const onClose: () => void = () => {
        setLoading(false)
        setError(null)
        setNameError(null)
        if (props.onClose) props.onClose()
    }

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [nameError, setNameError] = useState<string | null>(null)

    const onSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setError(null)
        setNameError(null)

        const data = new FormData(event.currentTarget as HTMLFormElement)
        const id = data.get("id") as string
        const name = data.get("name") as string
        props.adapter.updateOne(props.login, {id, name})
                .then(() => {
                    if (props.onSubmitted) props.onSubmitted()
                })
                .catch(error => setError(error.message)).finally(() => setLoading(false))
    }

    return (<FormDialog id={props.id} title={props.title} submitButton="Edit"
                        errorTitle="Failed to edit element." error={error} open={props.open}
                        isLoading={isLoading}
                        onClose={onClose} onSubmit={onSubmit}>
        <TenantEditForm id={`${props.id}-form`}
                        element={props.element}
                        nameError={nameError}
        />
    </FormDialog>)
}
