import {Alert, Box, Grid, Link, TextField} from "@mui/material";
import React, {SyntheticEvent} from "react";
import {useTheme} from "@mui/material/styles";
import {LoadingButton} from "@mui/lab";

export interface Props {
    id: string
    emailError: string | null
    passwordError: string | null
    isLoading: boolean
    onSubmit: (event: SyntheticEvent) => void
}

export default function (props: Props) {

    const theme = useTheme()

    return (<Box component="div">
        {props.emailError != null || props.passwordError != null ? (
                <Alert severity="error">
                    Login failed.
                </Alert>
        ) : null}
        <form id={`${props.id}-form`} noValidate method="post" onSubmit={props.onSubmit}>
            <TextField id="email"
                       error={props.emailError != null}
                       helperText={props.emailError == null ? null : props.emailError}
                       variant="outlined"
                       margin="normal"
                       required
                       fullWidth
                       label="Email Address"
                       name="email"
                       autoComplete="email"
                       autoFocus/>
            <TextField id="password"
                       error={props.passwordError != null}
                       helperText={props.passwordError == null ? null : props.passwordError}
                       variant="outlined"
                       margin="normal"
                       required
                       fullWidth
                       name="password"
                       label="Password"
                       type="password"
                       autoComplete="password"/>
            <LoadingButton id="login"
                           type="submit"
                           fullWidth
                           variant="contained"
                           color="primary"
                           loading={props.isLoading}
                           sx={{margin: theme.spacing(3, 0, 2)}}>Login</LoadingButton>
            <Grid container>
                <Grid item xs>
                    <Link href={`${process.env.GATSBY_PATH_PREFIX}/password-forgotten`}
                          variant="body2">
                        Forgot password?
                    </Link>
                </Grid>
            </Grid>
        </form>
    </Box>)
}
