import React, {SyntheticEvent, useContext, useEffect, useState} from "react";
import {
    Alert,
    AlertTitle,
    Box,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    Typography
} from "@mui/material";
import AuthenticatedLayout from "../../components/layout/AuthenticatedLayout";
import {DeleteSharp, EditSharp} from "@mui/icons-material";
import DeleteDialog from "../../components/dialog/DeleteDialog";
import {FlatPaper} from "../Misc";
import {LoginContext} from "../provider/LoginProvider";
import {TenantContext} from "../provider/TenantProvider";
import FormDialog from "../../components/dialog/FormDialog";
import {TenantSettingDefinition} from "../../adapters/TenantSettingAdapter";
import {TenantSettingResponse} from "../../generated/models/TenantSettingResponse";
import {Page, PageableRequest, tenantSettingAdapter} from "../../adapters/interfaces";
import {TenantSettingDefinitionResponse} from "../../generated/models/TenantSettingDefinitionResponse";
import {RouteComponentProps} from "@reach/router";

export interface Props extends RouteComponentProps {
    title: string
}

interface Setting {
    definition: TenantSettingDefinitionResponse
    value: string | null
}

export default function (props: Props) {
    const {login} = useContext(LoginContext)
    const {tenant} = useContext(TenantContext)
    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [settingsDefs, setSettingsDefs] = useState<TenantSettingDefinitionResponse[]>([])
    const [settingsPage, setSettingsPage] = useState<Page<TenantSettingResponse> | null>(null)
    const [formError, setFormError] = useState<string | null>(null)
    const [formValueError, setFormValueError] = useState<string | null>(null)
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false)
    const [isLoadingEditDialog, setLoadingEditDialog] = useState<boolean>(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
    const [isLoadingDeleteDialog, setLoadingDeleteDialog] = useState<boolean>(false)
    const [editSetting, setEditSetting] = useState<TenantSettingDefinitionResponse | null>(null)
    const [editSettingValue, setEditSettingValue] = useState<string | null>(null)
    const [editSettingSuccessFunction, setEditSettingSuccessFunction] = useState<(value: string | null) => {}>(() => {
        return (): void => {
        }
    })
    const [smtpEnabled, setSmtpEnabled] = useState<Setting | null>(null)
    const [smtpFromAddress, setSmtpFromAddress] = useState<Setting | null>(null)
    const [smtpFromName, setSmtpFromName] = useState<Setting | null>(null)
    const [smtpReplyAddress, setSmtpReplyAddress] = useState<Setting | null>(null)
    const [smtpHost, setSmtpHost] = useState<Setting | null>(null)
    const [smtpPort, setSmtpPort] = useState<Setting | null>(null)
    const [smtpUser, setSmtpUser] = useState<Setting | null>(null)
    const [smtpPassword, setSmtpPassword] = useState<Setting | null>(null)
    const [smtpAuthEnabled, setSmtpAuthEnabled] = useState<Setting | null>(null)
    const [smtpStartTlsEnabled, setSmtpStartTlsEnabled] = useState<Setting | null>(null)
    const [smtpSslProtocols, setSmtpSslProtocols] = useState<Setting | null>(null)
    const [smtpSslTrustedHosts, setSmtpSslTrustedHosts] = useState<Setting | null>(null)
    const [smtpTimeout, setSmtpTimeout] = useState<Setting | null>(null)
    const [smtpConnectionTimeout, setSmtpConnectionTimeout] = useState<Setting | null>(null)

    const assembleSetting = (definition: TenantSettingDefinition): Setting | null => {
        const def = settingsDefs.find(it => it.name === TenantSettingDefinition[definition])
        if (!def) return null
        const setting = settingsPage?.elements.find(it => it.name === def.name)
        return {definition: def, value: setting?.value || null}
    }

    const loadSettings = (): Promise<void> => {
        setLoading(true)
        const currentTenantId = tenant?.tenant.id
        return tenantSettingAdapter.findDefinitions(login, new PageableRequest(0, 1000))
                .then(response => {
                    setSettingsDefs(response.elements)
                    return tenantSettingAdapter.find(login, new PageableRequest(0, 1000, `tenantId==${currentTenantId}`, "+name"))
                })
                .then(response => {
                    setSettingsPage(response)
                })
                .catch(error => setError(error.message))
                .finally(() => setLoading(false))
    }

    const updateSetting = (name: string, value: string | null): Promise<any> => {
        const tenantId = tenant!.tenant.id;
        if (value == null) {
            return tenantSettingAdapter.deleteOne(login, {tenantId, name})
        } else if (settingsPage?.elements.find(s => s.name === name)) {
            return tenantSettingAdapter.updateOne(login, {tenantId, name, value})
        } else {
            return tenantSettingAdapter.createOne(login, {tenantId, name, value})
        }
    }

    const handleSettingEdit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoadingEditDialog(true)
        setFormError(null)
        setFormValueError(null)
        const target = event.target as typeof event.target & {
            name: { value: string }
            value: { value: string }
        }
        updateSetting(target.name.value, target.value.value)
                .then((response) => {
                    editSettingSuccessFunction(response?.value)
                    setEditDialogOpen(false)
                    setEditSettingValue(null)
                    setEditSetting(null)
                    setFormValueError(null)
                    setFormError(null)
                })
                .then(() => loadSettings())
                .catch(error => setFormError(error.message))
                .finally(() => setLoadingEditDialog(false))
    }

    const handleSettingDelete = (name: string): void => {
        setLoadingDeleteDialog(true)
        setFormError(null)
        setFormValueError(null)
        tenantSettingAdapter.deleteOne(login, {tenantId: tenant!.tenant.id, name})
                .then(() => loadSettings())
                .then(() => setDeleteDialogOpen(false))
                .catch(error => setFormError(`Failed to delete setting: ${error.message}`))
                .finally(() => setLoadingDeleteDialog(false))
    }

    useEffect(() => {
        setSmtpEnabled(assembleSetting(TenantSettingDefinition.SMTP_ENABLED))
        setSmtpFromAddress(assembleSetting(TenantSettingDefinition.SMTP_FROM_ADDRESS))
        setSmtpFromName(assembleSetting(TenantSettingDefinition.SMTP_FROM_NAME))
        setSmtpReplyAddress(assembleSetting(TenantSettingDefinition.SMTP_REPLY_ADDRESS))
        setSmtpHost(assembleSetting(TenantSettingDefinition.SMTP_HOST))
        setSmtpPort(assembleSetting(TenantSettingDefinition.SMTP_PORT))
        setSmtpUser(assembleSetting(TenantSettingDefinition.SMTP_USER))
        setSmtpPassword(assembleSetting(TenantSettingDefinition.SMTP_PASSWORD))
        setSmtpAuthEnabled(assembleSetting(TenantSettingDefinition.SMTP_AUTH_ENABLED))
        setSmtpStartTlsEnabled(assembleSetting(TenantSettingDefinition.SMTP_STARTTLS_ENABLED))
        setSmtpSslProtocols(assembleSetting(TenantSettingDefinition.SMTP_SSL_PROTOCOLS))
        setSmtpSslTrustedHosts(assembleSetting(TenantSettingDefinition.SMTP_SSL_TRUSTED_HOSTS))
        setSmtpTimeout(assembleSetting(TenantSettingDefinition.SMTP_TIMEOUT))
        setSmtpConnectionTimeout(assembleSetting(TenantSettingDefinition.SMTP_CONNECTION_TIMEOUT))
    }, [settingsDefs, settingsPage])
    useEffect(() => {
        // loading is for not loading multiple times
        if (!isLoading && settingsPage == null) {
            loadSettings()
        }
    })

    return (<AuthenticatedLayout title={props.title}
                                 breadcrumbs={[{name: "Overview", link: "/app"}, {
                                     name: props.title,
                                     link: props.uri || ""
                                 }]}>
        {error != null ? (
                <FlatPaper>
                    <Alert severity="error">
                        <AlertTitle>Failed to load elements</AlertTitle>
                        {error}
                    </Alert>
                </FlatPaper>
        ) : (
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} id="smtp-settings-area">
                        <FlatPaper variant="outlined">
                            <Box m={2}>
                                <Typography variant="h6" component="h3">Mail settings</Typography>
                                <List dense>
                                    {[
                                        {get: smtpEnabled, set: setSmtpEnabled},
                                        {get: smtpFromAddress, set: setSmtpFromAddress},
                                        {get: smtpFromName, set: setSmtpFromName},
                                        {get: smtpReplyAddress, set: setSmtpReplyAddress},
                                        {get: smtpHost, set: setSmtpHost},
                                        {get: smtpPort, set: setSmtpPort},
                                        {get: smtpUser, set: setSmtpUser},
                                        {get: smtpPassword, set: setSmtpPassword},
                                        {get: smtpAuthEnabled, set: setSmtpAuthEnabled},
                                        {get: smtpStartTlsEnabled, set: setSmtpStartTlsEnabled},
                                        {get: smtpSslProtocols, set: setSmtpSslProtocols},
                                        {get: smtpSslTrustedHosts, set: setSmtpSslTrustedHosts},
                                        {get: smtpTimeout, set: setSmtpTimeout},
                                        {get: smtpConnectionTimeout, set: setSmtpConnectionTimeout},
                                    ].map(it => {
                                        if (!it || !it.get || !it.get.definition) return null
                                        const def = it.get.definition
                                        const value = it.get.value != null ? def.encrypted ? '****************' : it.get.value : def._default == null ? '-' : def._default
                                        return (<ListItem id={`setting-${def.name}`}>
                                            <ListItemText primary={def.displayName}
                                                          secondary={value}/>
                                            <ListItemSecondaryAction>
                                                <IconButton id={`setting-edit-${def.name}`} edge="end" onClick={() => {
                                                    setEditSetting(it.get?.definition || null)
                                                    setEditSettingValue(it.get?.value || null)
                                                    setEditSettingSuccessFunction(() => (value: string | null) => {
                                                        it.set({definition: def, value})
                                                    })
                                                    setEditDialogOpen(true)
                                                }}><EditSharp/></IconButton>
                                                <IconButton id={`setting-delete-${def.name}`} edge="end"
                                                            disabled={!it.get?.value}
                                                            onClick={() => {
                                                                setEditSetting(it.get?.definition || null)
                                                                setEditSettingValue(it.get?.value || null)
                                                                setEditSettingSuccessFunction(() => (value: string | null) => {
                                                                    it.set({definition: def, value})
                                                                })
                                                                setDeleteDialogOpen(true)
                                                            }}><DeleteSharp/></IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>)
                                    })}
                                </List>
                            </Box>
                        </FlatPaper>
                        <FormDialog id="edit-dialog" title={editSetting?.displayName || ''} submitButton="Edit"
                                    errorTitle="Failed to edit element." error={formError} open={editDialogOpen}
                                    isLoading={isLoadingEditDialog}
                                    onClose={() => {
                                        setEditDialogOpen(false)
                                        setEditSettingValue(null)
                                        setEditSetting(null)
                                        setFormValueError(null)
                                        setFormError(null)
                                    }} onSubmit={handleSettingEdit}>
                            <Grid item xs={12}>
                                <input type="hidden" name="name" value={editSetting?.name || ''}/>
                                <TextField id={`form-${editSetting?.name}`} name="value" label={editSetting?.name}
                                           type={editSetting?.encrypted ? "password" : undefined}
                                           error={formValueError != null} required
                                           variant="outlined" fullWidth autoFocus
                                           InputLabelProps={{shrink: true}}
                                           inputProps={{pattern: editSetting?.regex}}
                                           helperText={formValueError ? `${editSetting?.help} ${formValueError}` : editSetting?.help}
                                           placeholder={editSetting?._default}
                                           onInvalid={event => {
                                               const target = event.target as typeof event.target & {
                                                   pattern: string
                                               }
                                               setFormValueError(`Value must match pattern ${target.pattern}`)
                                           }}
                                           defaultValue={editSettingValue}/>
                            </Grid>
                        </FormDialog>
                        <DeleteDialog title={editSetting?.displayName || ''} errorTitle="Failed to delete element."
                                      error={formError} open={deleteDialogOpen} isLoading={isLoadingDeleteDialog}
                                      onClose={() => setDeleteDialogOpen(false)}
                                      onSubmit={() => handleSettingDelete(editSetting?.name!)}>
                            Do you really want to reset setting <strong>{editSetting?.displayName}</strong>?
                        </DeleteDialog>
                    </Grid>
                </Grid>
        )}
    </AuthenticatedLayout>)
}
