import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Tooltip
} from "@mui/material";
import {HelpOutlineSharp} from "@mui/icons-material";
import React, {ChangeEvent, useEffect, useState} from "react";

export interface Props {
    id: string
    label?: string
    name?: string
    value?: string | null
    error: string | null
    helpMessage?: string
    invalidMessage?: string
    maxLength?: number
    autoFocus?: boolean
    required?: boolean
    readonly?: boolean
    disabled?: boolean
}

export default function (props: Props) {
    const DEFAULT_LABEL: string = "Email"
    const DEFAULT_NAME: string = "email"
    const DEFAULT_HELP_MESSAGE: string = "Email address"
    const DEFAULT_INVALID_MESSAGE: string = "Invalid email"
    const DEFAULT_MAX_LENGTH: number = 100

    const name: string = props.name || DEFAULT_NAME
    const label: string = props.label || DEFAULT_LABEL
    const helpMessage: string | null = props.helpMessage === undefined ? DEFAULT_HELP_MESSAGE : props.helpMessage
    const maxLength: number | null = props.maxLength === undefined ? DEFAULT_MAX_LENGTH : props.maxLength
    const required: boolean = props.required === undefined ? true : props.required

    const [value, setValue] = useState<string | null>(props.value === undefined ? null : props.value)
    const [error, setError] = useState<string | null>(props.error)

    useEffect(() => setValue(props.value === undefined ? null : props.value), [props.value])
    useEffect(() => setError(props.error), [props.error])

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setValue(event.target.value)
        if (error) setError(null)
    }
    const handleValidationEvent = (): void => {
        setError(props.invalidMessage || DEFAULT_INVALID_MESSAGE)
    }

    return (<FormControl id={`${props.id}-form-${name}`} variant="outlined" size="small"
                         error={error != null} fullWidth required={required} disabled={props.disabled}>
        <InputLabel htmlFor={props.id}>{label}</InputLabel>
        <OutlinedInput id={props.id}
                       name={name}
                       label={label}
                       type="email"
                       autoFocus={props.autoFocus}
                       endAdornment={helpMessage ? (
                               <InputAdornment position="end">
                                   <Tooltip title={helpMessage} placement="left">
                                       <IconButton tabIndex={-1} edge="end"><HelpOutlineSharp/></IconButton>
                                   </Tooltip>
                               </InputAdornment>
                       ) : null}
                       inputProps={{readOnly: props.readonly, maxLength: props.maxLength, inputMode: "email"}}
                       onChange={handleChange}
                       onInvalid={handleValidationEvent} value={value == null ? undefined : value}/>
        <Grid container>
            {maxLength ? (<>
                <Grid item xs={10}>
                    <FormHelperText>{error}</FormHelperText>
                </Grid>
                <Grid item xs={2}><Grid container justifyContent="flex-end">
                    <FormHelperText>{`${value == null ? 0 : value.length}/${maxLength}`}</FormHelperText>
                </Grid></Grid>
            </>) : (<Grid item xs={12}>
                <FormHelperText>{error}</FormHelperText>
            </Grid>)}
        </Grid>
    </FormControl>)
}
