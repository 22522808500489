import React from "react";
import {ApiKeyResponse} from "../../../generated/models/ApiKeyResponse";
import {
    Alert,
    AlertTitle,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery
} from "@mui/material";
import {WarningButton} from "../../Buttons";
import {useTheme} from "@mui/material/styles";

export interface Props {
    id: string
    title: string
    open: boolean
    element: ApiKeyResponse | null
    onClose?: () => void
}

export default function (props: Props) {

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"))

    return (<Dialog id={props.id} open={props.open} onClose={props.onClose} fullScreen={fullScreen}
                    aria-labelledby={`${props.id}-title`} transitionDuration={0}>
        <DialogTitle id={`${props.id}-title`}>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText component="div">
                <Alert id={`${props.id}-form-error`} severity="warning">
                    <AlertTitle>{props.element?.description} API key</AlertTitle>
                    This password is only shown for a limited period of time and will not be shown ever
                    again!
                </Alert>
            </DialogContentText>
            <DialogContentText component="div">
                <Box fontFamily="Fira Code, monospace" fontWeight="bold" letterSpacing={3}
                     textAlign="center">
                    {props.element?.password}
                </Box>
            </DialogContentText>
            <DialogActions>
                <WarningButton id={`${props.id}-close`} variant="contained"
                               onClick={props.onClose}>OK</WarningButton>
            </DialogActions>
        </DialogContent>
    </Dialog>)
}
