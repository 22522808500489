import React, {SyntheticEvent, useState} from "react";
import FormDialog from "../../dialog/FormDialog";
import {LoginHolder} from "../../provider/LoginProvider";
import {ApiKeyResponse} from "../../../generated/models/ApiKeyResponse";
import {ApiKeyAdapter} from "../../../adapters/ApiKeyAdapter";
import ApiKeyCreateForm from "./ApiKeyCreateForm";
import {RoleResponse} from "../../../generated/models/RoleResponse";
import {CreateApiKeyRoleRequest} from "../../../generated/models/CreateApiKeyRoleRequest";

export interface Props {
    id: string
    title: string
    open: boolean
    tenantId: string,
    roles: RoleResponse[]
    onClose?: () => void
    onSubmitted?: (response: ApiKeyResponse) => void
    adapter: ApiKeyAdapter
    login: LoginHolder | null
}

export default function (props: Props) {

    const onClose: () => void = () => {
        setLoading(false)
        setError(null)
        setDescriptionError(null)
        setRolesError(null)
        if (props.onClose) props.onClose()
    }

    const [isLoading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)
    const [descriptionError, setDescriptionError] = useState<string | null>(null)
    const [rolesError, setRolesError] = useState<string | null>(null)

    const onSubmit = (event: SyntheticEvent): void => {
        event.preventDefault()
        setLoading(true)
        setError(null)
        setDescriptionError(null)
        setRolesError(null)

        const data = new FormData(event.currentTarget as HTMLFormElement)
        const description = (data.get("description") as string).trim()
        const roleData = data.get("roles") as string
        const roleIds: string[] = roleData.split(",").filter((it: string) => !!it).map((it: string) => it)
        const roles: CreateApiKeyRoleRequest[] = roleIds.map(roleId => ({tenantId: props.tenantId, roleId}))
        props.adapter.createOne(props.login, {description, roles})
                .then(response => {
                    if (props.onSubmitted) props.onSubmitted(response)
                })
                .catch(error => setError(error.message))
                .finally(() => setLoading(false))
    }

    return (<FormDialog id={props.id} title={props.title} submitButton="Create"
                        errorTitle="Failed to create element." error={error} open={props.open}
                        isLoading={isLoading}
                        onClose={onClose} onSubmit={onSubmit}>
        <ApiKeyCreateForm id={`${props.id}-form`}
                          roles={props.roles}
                          descriptionError={descriptionError}
                          rolesError={rolesError}
        />
    </FormDialog>)
}
