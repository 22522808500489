import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {UserResponse} from "../../../generated/models/UserResponse";
import {TenantResponse} from "../../../generated/models/TenantResponse";
import {RoleResponse} from "../../../generated/models/RoleResponse";
import {UserRoleResponse} from "../../../generated/models/UserRoleResponse";
import EmailInput from "../../form/EmailInput";
import TextInput from "../../form/TextInput";
import {timestamp} from "../../../misc/timestamp";
import TenantInput from "../../form/TenantInput";
import RoleInput from "../../form/RoleInput";

export interface Props {
    id: string
    element: UserResponse | null
    elementTenants: TenantResponse[]
    elementRoles: UserRoleResponse[]
    tenants: TenantResponse[]
    roleDefsPerTenant: Record<string, RoleResponse[]>
    onTenantsChange: (tenants: TenantResponse[]) => void

    emailError: string | null
    firstnameError: string | null
    lastnameError: string | null
    tenantsError: string | null
    rolesError: string | null
}

export default function (props: Props) {
    const [emailError, setEmailError] = useState<string | null>(props.emailError)
    const [firstnameError, setFirstnameError] = useState<string | null>(props.firstnameError)
    const [lastnameError, setLastnameError] = useState<string | null>(props.lastnameError)

    useEffect(() => setEmailError(props.emailError), [props.emailError])
    useEffect(() => setFirstnameError(props.firstnameError), [props.firstnameError])
    useEffect(() => setLastnameError(props.lastnameError), [props.lastnameError])

    return (<>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-id`} label="ID" name="id"
                       value={props.element?.id}
                       required={true}
                       readonly={true}
                       maxLength={36}
            />
        </Grid>
        <Grid item xs={12}>
            <EmailInput id={`${props.id}-email`}
                        value={props.element?.email}
                        error={emailError}
                        autoFocus={true}
                        helpMessage="The email of the user to be used for the login."
            />
        </Grid>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-firstname`} label="Firstname" name="firstname"
                       value={props.element?.firstname}
                       error={firstnameError}
                       required={false} helpMessage="The firstname of the user."
            />
        </Grid>
        <Grid item xs={12}>
            <TextInput id={`${props.id}-lastname`} label="Lastname" name="lastname"
                       value={props.element?.lastname}
                       error={lastnameError}
                       required={false} helpMessage="The lastname of the user."
            />
        </Grid>
        <Grid item xs={12}>
            <TenantInput id={`${props.id}-tenants`}
                         options={props.tenants || []}
                         onChange={props.onTenantsChange}
                         value={props.elementTenants}
                         error={props.tenantsError}
            />
        </Grid>
        {props.elementTenants && props.elementTenants.map(tenant => (
                <Grid key={tenant.id} item xs={12}>
                    <RoleInput id={`${props.id}-roles-${tenant.id}`}
                               name={`roles-${tenant.id}`}
                               options={props.roleDefsPerTenant?.[tenant.id] || []}
                               value={props.roleDefsPerTenant?.[tenant.id]?.filter(r => props.elementRoles?.find(ur => ur.roleId === r.id) || false) || undefined}
                               error={props.rolesError}
                               helpMessage={`Select roles for tenant ${tenant.name}.`}
                    />
                </Grid>
        ))}
        <Grid item xs={12} sm={6}>
            <TextInput id={`${props.id}-createdAt`} label="Created" name="createdAt"
                       value={timestamp(props.element?.createdAt || null).format()} required={true} readonly={true}
                       maxLength={0}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput id={`${props.id}-updatedAt`} label="Updated" name="updatedAt"
                       value={timestamp(props.element?.updatedAt || null).format()} required={true} readonly={true}
                       maxLength={0}
            />
        </Grid>
    </>)
}
